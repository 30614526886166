import React from 'react';
import { connect } from 'react-redux';

import { StoreT } from '@global/types.ts';

import close from './methods/close.ts';
import save from './methods/save.ts';

import WalletsAccountI from './types.ts';

import EditBlock from '../../editBlock/EditBlock.tsx';
import renderContent from './renders/renderContent.tsx';
import renderDesktop from './renders/renderDesktop.tsx';
import renderFields from './renders/renderFields.tsx';
import renderInput from './renders/renderInput.tsx';
import renderMobile from './renders/renderMobile.tsx';
import renderTitle from './renders/renderTitle.tsx';

class WalletsAccountPopup
    extends EditBlock<WalletsAccountI['props'], WalletsAccountI['state']>
    implements WalletsAccountI
{
    parent: WalletsAccountI['parent'];

    constructor(props: WalletsAccountI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    name = 'fields';
    savedName = 'savedFields';

    renderContent = renderContent;
    renderTitle = renderTitle;
    renderInput = renderInput;
    renderFields = renderFields;

    renderDesktop = renderDesktop;
    renderMobile = renderMobile;

    save = save;
    close = close;

    componentDidMount() {
        this.init({ fields: {} });
    }

    render() {
        return (
            <>
                {this.renderDesktop()}
                {this.renderMobile()}
            </>
        );
    }
}

function mapStateToProps(state: StoreT) {
    return {
        device: state.device,
        windowHeight: state.windowHeight,
    };
}

export default connect(mapStateToProps)(WalletsAccountPopup);
