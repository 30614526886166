import React from 'react';

import { CustomListenerT } from '@global/types.ts';

import animate from './methods/animate.ts';
import checkChangeDisabled from './methods/checkChangeDisabled.ts';
import checkShow from './methods/checkShow.ts';
import disabledDocument from './methods/disabledDocument.ts';
import getDownBreak from './methods/getDownBreak.ts';
import getInner from './methods/getInner.ts';
import getMaxHeight from './methods/getMaxHeight.ts';
import getScroll from './methods/getScroll.ts';
import handlerEnd from './methods/handlerEnd.ts';
import handlerEvents from './methods/handlerEvents.ts';
import handlerMove from './methods/handlerMove.ts';
import handlerStart from './methods/handlerStart.ts';
import hide from './methods/hide.ts';
import move from './methods/move.ts';
import popupChange from './methods/popupChange.ts';
import setMaxHeight from './methods/setMaxHeight.ts';
import sizeInit from './methods/sizeInit.ts';

import MobPopupI from './types.ts';

class MobPopup extends React.Component<MobPopupI['props']> implements MobPopupI {
    parent: MobPopupI['parent'];
    checkHideDisabled: MobPopupI['checkHideDisabled'];
    isDisabled: MobPopupI['isDisabled'];
    isDefaultHide: MobPopupI['isDefaultHide'];
    name: MobPopupI['name'];

    constructor(props: MobPopupI['props']) {
        super(props);
        this.state = {};

        this.handlerStart = this.handlerStart.bind(this);
        this.handlerMove = this.handlerMove.bind(this);
        this.handlerEnd = this.handlerEnd.bind(this);

        this.popupChange = this.popupChange.bind(this);

        this.name = this.props.name;

        this.parent = React.createRef();
    }

    startY = 0;
    moveY = 0;
    lastY = 0;
    isProccess = false;
    deltaY = null;

    getInner = getInner;
    getScroll = getScroll;
    getDownBreak = getDownBreak;
    hide = hide;
    disabledDocument = disabledDocument;

    move = move;
    handlerStart = handlerStart;
    handlerMove = handlerMove;
    handlerEnd = handlerEnd;
    getMaxHeight = getMaxHeight;
    setMaxHeight = setMaxHeight;
    handlerEvents = handlerEvents;
    checkChangeDisabled = checkChangeDisabled;
    checkShow = checkShow;
    animate = animate;
    popupChange = popupChange;
    sizeInit = sizeInit;

    componentDidMount(): void {
        if (typeof this.checkHideDisabled === 'function') {
            this.isDisabled = this.checkHideDisabled();
        }

        this.handlerEvents(true);

        this.sizeInit();

        (document.addEventListener as CustomListenerT)('popupChange', this.popupChange);
    }

    componentDidUpdate(): void {
        this.setMaxHeight();
        this.checkChangeDisabled();
        this.sizeInit();
    }

    componentWillUnmount(): void {
        this.handlerEvents(false);

        (document.removeEventListener as CustomListenerT)('popupChange', this.popupChange);
    }

    render() {
        const { children, name } = this.props;

        return (
            <div ref={this.parent} className={`popup _COL _${name}`}>
                <div className="popup__mob">
                    <div className="popup__mobBox _NOSCROLL">
                        <div className="popup__mobInner">{children}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MobPopup;
