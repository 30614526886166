import axios from 'axios';

import reqDownload from '@functions/reqDownload.ts';

import getHeaders from '../../../../functions/getHeaders.ts';
import handlerLoading from '../../../../functions/handlerLoading.ts';

import WalletsAccountI from '../types.ts';

const save: WalletsAccountI['save'] = async function () {
    const stateFields = this.state.fields!;

    await handlerLoading.call(this, 'save');

    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API}/balance?dowloadAmount=true&amount=${stateFields.amount}`,
            { headers: getHeaders(), responseType: 'blob' },
        );

        if (response.data.type === 'application/json') {
            const json = await response.data.text();
            const error = JSON.parse(json);

            if (error?.data?.error) {
                this.setState({ error: { text: error.data.error.text } });
            }
        } else {
            await reqDownload({ response });

            this.close();
        }
    } catch (error) {
        console.log(error);
    }

    await handlerLoading.call(this, null);
};

export default save;
