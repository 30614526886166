export default function pageScrollHandler(
    name: string | null,
    { target }: { target: EventTarget },
): void {
    document.dispatchEvent(
        new CustomEvent('pageScroll', {
            detail: { name, scrollTop: (target as HTMLElement).scrollTop },
        }),
    );
}
