const list = {
    order: {
        content: 'Создать заказ',
        icon: 'profile-order',
        pageName: 'public-order',
    },
    'profile-orders': {
        icon: 'profile-orders',
        pageName: 'profile-orders',
    },
    'profile-settings': {
        icon: 'profile-settings',
        pageName: 'profile-settings',
    },
    'profile-company': {
        icon: 'profile-company',
        pageName: 'profile-company',
    },
    'profile-wallets': {
        icon: 'profile-wallets',
        pageName: 'profile-wallets',
    },
    'profile-clients': {
        icon: 'profile',
        pageName: 'profile-clients',
    },
    'profile-workers': {
        icon: 'profile',
        pageName: 'profile-workers',
    },
    'profile-operations': {
        icon: 'profile-wallets',
        pageName: 'profile-operations',
    },
    'profile-chats': {
        icon: 'profile-chat',
        pageName: 'profile-chats',
    },
    logout: {
        content: 'Выйти',
        icon: 'profile-logout',
    },
} as const;

export default list;
