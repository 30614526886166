import React from 'react';

import Media from '@components/media/Media.tsx';
import MobPopup from '@components/mobPopup/MobPopup.tsx';
import handlerPopup from '@functions/handlerPopup.ts';

import AfterRegI from '../types.ts';

const renderMobile: AfterRegI['renderMobile'] = function () {
    const { windowHeight } = this.props;

    return (
        <>
            <Media current="mobile">
                <MobPopup
                    windowHeight={windowHeight}
                    hideCallback={() => {
                        handlerPopup('afterRegPopup', { isShow: false });
                    }}
                    isInit={true}
                >
                    {this.renderContent()}
                </MobPopup>
            </Media>
        </>
    );
};

export default renderMobile;
