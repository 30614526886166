import React from 'react';
import { connect } from 'react-redux';

import ListAbsoluteMain from '@components/listAbsoluteMain/ListAbsoluteMain.tsx';
import mobMenuScrollTargetsTrigger from '@functions/mobMenuScrollTargetsTrigger.ts';
import { s } from '@functions/seo.ts';
import { CustomListenerT, StoreT } from '@global/types.ts';

import changePage from './methods/changePage.ts';
import checkChangePage from './methods/checkChangePage.ts';
import getPagesOrder from './methods/getPagesOrder.ts';

import PagesI from './types.ts';

import renderPage from './renders/renderPage.tsx';

class Pages<T extends {}>
    extends React.Component<PagesI<T>['props'], PagesI<T>['state']>
    implements PagesI<T>
{
    constructor(props: PagesI<T>['props']) {
        super(props);
        this.state = {};

        this.getPagesOrder = this.getPagesOrder.bind(this);
        this.renderPage = this.renderPage.bind(this);
        this.changePage = this.changePage.bind(this);
    }

    getPagesOrder = getPagesOrder;
    checkChangePage = checkChangePage;
    changePage = changePage;

    renderPage = renderPage;

    componentDidMount() {
        this.checkChangePage();

        this.setState({ classId: `pages-${new Date().getTime()}` });

        (document.addEventListener as CustomListenerT)('changePage', this.changePage);
    }

    componentDidUpdate() {
        this.checkChangePage();
    }

    componentWillUnmount() {
        (document.removeEventListener as CustomListenerT)('changePage', this.changePage);
    }

    render() {
        const { classId } = this.state;
        const {
            className = '',
            classNamePage = '',
            callback,
            isNotParams = true,
            keyRender,

            // name,
        } = this.props;

        return (
            <>
                <ListAbsoluteMain
                    className={className}
                    items={this.getPagesOrder()}
                    renderItem={this.renderPage}
                    classNameItem={`${classNamePage} ${classId}`}
                    prop="name"
                    isNotParams={isNotParams}
                    isNotParamsItem={true}
                    styles={['height']}
                    callback={(data) => {
                        if (!this.state.isInit) {
                            this.setState({ isInit: true });
                        }

                        if (callback) {
                            callback(data);
                        }

                        mobMenuScrollTargetsTrigger();
                    }}
                    resizeParent={
                        !s() ? (document.querySelector('.body__content') as HTMLElement) : undefined
                    }
                    keyRender={keyRender}
                    // name={name}
                    paramsParent={{ width: true }}
                    isSmoothShow={true}
                />
            </>
        );
    }
}

function mapStateToProps(state: StoreT) {
    return {
        storePages: state.pages,
        windowHeight: state.windowHeight,
    };
}

export default connect(mapStateToProps)(Pages);
