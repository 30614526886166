import React from 'react';

import MoreBtn from '@components/moreBtn/MoreBtn';
import { setCursorActions } from '@functions/actionsHandler';
import { CursorActionsItemT } from '@global/types';

type BodyT = {
    name: string;
    actions: CursorActionsItemT[];
};

export default function More({ name, actions }: BodyT): React.ReactNode {
    const props = setCursorActions(
        {
            _id: `${name}-table`,
            dir: 'left',
            items: actions,
        },
        {
            targetClassName: '.profileTable__more',
        },
    );

    return (
        <div className="profileTable__more">
            <MoreBtn {...props} />
        </div>
    );
}
