import React from 'react';

import WalletAccountI from '../types.ts';

const renderTitle: WalletAccountI['renderTitle'] = function () {
    return (
        <div className="popup__title _MOBMAX">
            <div className="popup__titleText">Добавьте способ оплаты</div>
            <div className="popup__titleDescription">
                Данные реквизиты будут использоваться
                <br />
                для формирования счёт на оплату
            </div>
        </div>
    );
};

export default renderTitle;
