import React from 'react';

import Icon from '@components/icon/Icon.tsx';
import Calendar from '@components/calendar/Calendar.tsx';
import Input from '@components/input/Input.tsx';

import FilterI from '../types.ts';

const renderBlock: FilterI['renderBlock'] = function ({ block, index }) {
    const { name: blockName, support, inputSupport, type, items, value } = block;

    return (
        <div className="filter__block" key={blockName}>
            <div className="filter__blockHead">
                {support}:
                <div
                    className="filter__blockHeadDelete _FOCUS _CLICK"
                    onClick={() => {
                        this.change({
                            [`${index}.value`]: type === 'checkbox' ? [] : '',
                            [`${index}.currentDate`]: null,
                            [`${index}.doubleCurrentDate`]: null,
                            [`${index}.clearedKey`]: new Date().getTime(),
                        });
                    }}
                >
                    <Icon name="delete" />
                </div>
            </div>

            <div className="filter__blockContent">
                {type === 'input' && (
                    <div className="filter__blockInput">
                        <Input
                            name={blockName}
                            className="_grey"
                            support={inputSupport}
                            value={value as string}
                            onChange={({ value: newValue }) => {
                                this.change({
                                    [`${index}.value`]: newValue,
                                });
                            }}
                        />
                    </div>
                )}
                {type === 'checkbox' && (
                    <div className="filter__blockItems _ROW">
                        {items.map((item) => {
                            const isCurrent = (value as string[]).indexOf(item.id) !== -1;

                            return (
                                <div
                                    className={`filter__blockItem _FOCUS _CLICK ${
                                        isCurrent ? '_current' : ''
                                    }`}
                                    key={item.id}
                                    onClick={() => {
                                        this.change({
                                            [`${index}.value`]: item.id,
                                        });
                                    }}
                                >
                                    {item.content}
                                </div>
                            );
                        })}
                    </div>
                )}
                {type === 'date' && (
                    <div className="filter__blockDate">
                        <Calendar
                            startDate={block.currentDate}
                            startDoubleDate={block.doubleCurrentDate}
                            inFilter={true}
                            momentCallback={({ ...props }) => {
                                const resultProps: {
                                    [s: string]: any;
                                } = {};

                                Object.keys(props).forEach((key) => {
                                    resultProps[`${index}.${key}`] = props[key];
                                });
                                this.change({ ...resultProps });
                            }}
                            isDouble={true}
                            withPast={true}
                            updatedKey={block.clearedKey}
                            init={this.setSizeInit.bind(this)}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default renderBlock;
