import { FilterQueryT } from '../../../global/types.ts';

import ItemsI, { RequestType } from '../types.ts';

const getQueryForRequest: ItemsI['getQueryForRequest'] = function () {
    const { counterScroll, filterQuery = [] } = this.state;
    const query: RequestType = {
        signal: this.controller?.signal,
        params: [],
    };
    const params: FilterQueryT[] = [];

    let skip = counterScroll ? counterScroll - this.stepCounter : 0;

    if (skip < 0) {
        skip = 0;
    }

    params.push(...filterQuery.map((item) => ({ key: `filter_${item.key}`, value: item.value })));

    params.push({ key: 'inList', value: true });
    params.push({ key: 'limit', value: this.stepCounter.toString() });
    params.push({ key: 'skip', value: skip.toString() });

    query.params = params;

    return query;
};

export default getQueryForRequest;
