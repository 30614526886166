import React from 'react';
import { connect } from 'react-redux';

import Icon from '@components/icon/Icon.tsx';
import { StoreT } from '@global/types.ts';

import ButtonI from './types.ts';

class AddButton extends React.Component<ButtonI['props'], ButtonI['state']> implements ButtonI {
    parent: ButtonI['parent'];

    constructor(props: ButtonI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { children, onClick } = this.props;

        return (
            <>
                <div ref={this.parent} className="addButton _CLICK" onClick={onClick}>
                    <i className="addButton__icon">
                        <Icon name="add-button" />
                    </i>
                    {children}
                </div>
            </>
        );
    }
}

function mapStateToProps(state: StoreT) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(AddButton);
