import React from 'react';

import Button from '@components/button/Button.tsx';

import WalletsAccountI from '../types.ts';

const renderFields: WalletsAccountI['renderFields'] = function () {
    const { loadingKey } = this.state;

    return (
        <div className="popup__fields _ROW _walletsAccount">
            <div className="popup__field _bic">
                {this.renderInput({
                    name: 'accountBic',
                    support: 'БИК',
                    regExp: /[^\d]/gi,
                    maxLen: 9,
                })}
            </div>
            <div className="popup__field _account">
                {this.renderInput({
                    name: 'accountNumber',
                    support: 'Банк. счёт',
                    regExp: /[^\d]/gi,
                    maxLen: 20,
                })}
            </div>
            <div className="popup__field _addressatName">
                {this.renderInput({
                    name: 'accountAddressatName',
                    support: 'ФИО получателя',
                    regExp: /[^а-яА-Я -]/gi,
                })}
            </div>
            <div className="popup__field _button">
                <Button onClick={this.save.bind(this)} loading={loadingKey === 'save'}>
                    Добавить способ оплаты
                </Button>
            </div>
        </div>
    );
};

export default renderFields;
