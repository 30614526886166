import React from 'react';

import AuthButton from '@components/authButton/AuthButton.tsx';
import Media from '@components/media/Media.tsx';

import MenuBtn from '../components/MenuBtn.tsx';

import TopBarI from '../types.ts';

const renderDesktop: TopBarI['renderDesktop'] = function () {
    const { handlerState, isShowMenu } = this.props;

    return (
        <Media current="desktop">
            <MenuBtn handlerState={handlerState} isShowMenu={isShowMenu}>
                Меню
            </MenuBtn>
            <div className={`publicTopBar__button _profile ${isShowMenu ? '_hide' : ''}`}>
                <AuthButton />
            </div>
        </Media>
    );
};

export default renderDesktop;
