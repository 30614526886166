const info = {
    orders: {
        title: 'Фильтр заказов',
    },
    operations: {
        title: 'Фильтр операций',
    },
    clients: {
        title: 'Фильтр клиентов',
    },
    chats: {
        title: 'Фильтр чатов',
    },
    workers: {
        title: 'Фильтр сотрудников',
    },
    logs: {
        title: 'Фильтр логов',
    },
} as const;

export default info;
