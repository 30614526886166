import { store } from '../redux/redux.ts';
import getSalt from './getSalt.ts';
import { getCookie } from './handlerCookies.ts';

type HeadersT = {
    hash: string;
    salt: string | null;
    userId: string;
};

export default function getHeaders(settings?: { id?: string }): Partial<HeadersT> {
    const { user } = store.getState();
    const userId = user?._id || settings?.id;
    const headers: Partial<HeadersT> = {};

    if (getCookie(process.env.REACT_APP_HASH)) {
        headers.hash = getCookie(process.env.REACT_APP_HASH);
        headers.salt = getSalt({ id: userId });
    }

    if (user) {
        headers.userId = user._id;
    }

    return headers;
}
