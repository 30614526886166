import React from 'react';

import AnimateChangeI from '../types.ts';

const renderItem: AnimateChangeI['renderItem'] = function ({ prop }) {
    const { className = '' } = this.props;

    return (
        <div className={`animateChange__item _child ${className}`}>
            {prop && this.childrens[prop]}
        </div>
    );
};

export default renderItem;
