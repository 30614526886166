import handlerLoading from '../../../functions/handlerLoading.ts';

import EditBlockI, { ModelT } from '../types.ts';

import createId from '../../../requests/createId.ts';

const addArrayItem: EditBlockI['addArrayItem'] = async function ({ name, key, item = {} }) {
    const { [this.name]: model } = this.state as ModelT;

    if (model?.TEMPLATES) {
        const template = model?.TEMPLATES[name];

        if (template) {
            await handlerLoading.call(this, key);

            try {
                const id = await createId();

                await this.change({
                    [key]: {
                        ...JSON.parse(JSON.stringify(template)),
                        _id: id,
                        FROM_ARRAY: true,
                        isNew: true,
                        ...item,
                    },
                });

                await handlerLoading.call(this, null);

                return id as string;
            } catch (error) {
                Promise.resolve();
            }

            await handlerLoading.call(this, null);
        }
    }

    return null;
};

export default addArrayItem;
