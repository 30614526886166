import React from 'react';

import ListAbsoluteMain from '@components/listAbsoluteMain/ListAbsoluteMain.tsx';
import ListScroll from '@components/listScroll/ListScroll.tsx';
import Media from '@components/media/Media.tsx';

import TableI from '../types.ts';

import InfoBlock from '../../infoBlock/InfoBlock.tsx';
import LoaderBlock from '../../loaderBlock/LoaderBlock.tsx';

const renderContent: TableI['renderContent'] = function () {
    const {
        isShowMoreLoader,
        items = [],
        isReady,
        isLimit,
        isDisabledScroll,
        isLoadingFilter,
    } = this.state;
    const { name, user, getScrollParent, init } = this.props;
    const isLoading = this.checkLoading();
    const table = this.tables[name];

    return (
        <div
            className={`profileTable__content ${isReady ? '_ready' : ''} ${!isReady || isLoading ? '_loading' : ''} ${getScrollParent ? '_hidden' : ''}`}
        >
            <LoaderBlock
                className="profileTable__loader"
                itemClassName="profileTable__loaderItem"
                isShow={!!isShowMoreLoader}
                isScroll={true}
            />
            <LoaderBlock
                className="profileTable__loader"
                itemClassName="profileTable__loaderItem"
                isShow={isLoading}
            />
            <InfoBlock
                isShow={items.length === 0 && !!isReady && !isLoading}
                title={table.info.title}
                description={table.info.description}
            />
            <div className="profileTable__contentScroll _NOSCROLL">
                <div className="profileTable__contentInner">
                    <Media current="desktop">
                        <div className="profileTable__row _ROW _head">
                            {this.renderCols({ head: true })}
                        </div>
                    </Media>
                    <ListScroll
                        getParent={
                            getScrollParent ||
                            (() =>
                                this.parent.current?.querySelector(
                                    '.profileTable__contentScroll',
                                ) as HTMLElement)
                        }
                        callback={this.getMoreItems}
                        startCounter={this.stepCounter}
                        stepCounter={this.stepCounter}
                        maxCounter={Infinity}
                        lengthCurrent={items.length}
                        handlerLoaderList={this.handlerLoaderList}
                        isLimit={!!isLimit}
                        isDisabledScroll={!!isDisabledScroll || !isReady || !!isLoadingFilter}
                    >
                        <ListAbsoluteMain
                            className="profileTable__rows _COL _ITEMS"
                            items={items}
                            realyItems={this.state.items}
                            renderItem={this.renderRow.bind(this)}
                            classNameItem="profileTable__row"
                            classNames={[`_${name}`, ...(user?.role === 'admin' ? ['_admin'] : [])]}
                            prop="_id"
                            paramsParent={{ width: true }}
                            styles={['height']}
                            resizeParent={document.querySelector('.body__content') as HTMLElement}
                            minHeight={items.length === 0 ? 200 : undefined}
                            callback={(data) => {
                                if (data.isInit && typeof init === 'function') {
                                    init();
                                }
                            }}
                            name={`table-${this.props.name}`}
                        />
                    </ListScroll>
                </div>
            </div>
        </div>
    );
};

export default renderContent;
