import { store } from '@redux/redux';

function remToPx(rem: number): number {
    const device = store.getState().device;

    if (device === 'mobile') {
        return rem;
    }

    return rem * (document.documentElement.clientWidth / 1512);
}

function pxToRem(px: number): number {
    const device = store.getState().device;

    if (device === 'mobile') {
        return px;
    }

    return px / (document.documentElement.clientWidth / 1512);
}

export { remToPx, pxToRem };
