import React from 'react';

import Animate from '@components/animate/Animate.tsx';
import PublicMenu from '@components/public/menu/Menu.tsx';
import { s } from '@functions/seo.ts';

import RootI from '../types.ts';

const renderPublicMenu: RootI['renderPublicMenu'] = function () {
    const { isShowPublicMenu } = this.props;

    return (
        <Animate className="body__publicMenu" isShow={isShowPublicMenu || s()}>
            <PublicMenu
                isShowMenu={isShowPublicMenu}
                handlerState={this.handlerMenuState.bind(this)}
            />
        </Animate>
    );
};

export default renderPublicMenu;
