import axios from 'axios';

import getHeaders from '../../../../functions/getHeaders.ts';
import handlerLoading from '../../../../functions/handlerLoading.ts';

import WalletsAccountI from '../types.ts';

const save: WalletsAccountI['save'] = async function () {
    const stateFields = this.state.fields!;
    const { callback } = this.props;

    await handlerLoading.call(this, 'save');

    const fields = {
        number: stateFields.accountNumber,
        bic: stateFields.accountBic,
        addressatName: stateFields.accountAddressatName,
    };

    try {
        const response = await axios.patch(
            `${process.env.REACT_APP_API}/user`,
            { action: 'addWallet', fields },
            { headers: getHeaders() },
        );
        const { success, data } = response.data;

        if (success) {
            this.close();

            if (callback) {
                callback();
            }
        } else {
            const { error } = data;

            if (error) {
                this.setState({ error });
            }
        }
    } catch (error) {
        console.log(error);
    }

    await handlerLoading.call(this, null);
};

export default save;
