import { MouseEvent } from 'react';

import actionsInfo from '@components/cursorActions/static/actions';
import { ActionT } from '@components/cursorActions/types';
import { CursorActionsT, ListenerT } from '@global/types';
import { dispatcher, store } from '@redux/redux';

type SettingsT = {
    targetClassName: string;
    className?: string;
};

const deleteCursorActions = function ({ id }: { id: CursorActionsT['_id'] }): void {
    const cursorActions = store.getState().cursorActions.map((item) => ({ ...item }));

    const index = cursorActions.findIndex((item) => item._id === id);

    if (index !== -1) {
        cursorActions.splice(index, 1);

        dispatcher({ type: 'cursorActions', data: cursorActions });

        const observerIndex = observers.findIndex((item) => item.id === id);

        if (observerIndex !== -1) {
            observers[observerIndex].observer.disconnect();
            (document.removeEventListener as ListenerT<MouseEvent>)(
                'click',
                observers[observerIndex].documentClickHandler,
            );
            document.removeEventListener(
                'changeWidthWindow',
                observers[observerIndex].documentResizeHandler,
            );

            observers.splice(observerIndex, 1);
        }
    }
};

const getSizeItem = function ({
    actions,
    settings,
}: {
    actions: CursorActionsT;
    settings: SettingsT;
}): {
    width: number;
    height: number;
} {
    const div = document.createElement('div');

    div.classList.add('cursorActions__item');

    if (settings.className) {
        div.classList.add(settings.className);
    }

    actions.items.forEach((item) => {
        const actionDiv = document.createElement('div');
        const actionInfo = actionsInfo[item.key] as ActionT;

        actionDiv.classList.add('cursorActions__itemAction');

        actionDiv.innerHTML = (actionInfo.text || item.text)!;

        div.appendChild(actionDiv);
    });

    div.style.position = 'absolute';
    div.style.zIndex = '-1';
    div.style.top = '0';
    div.style.left = '0';
    div.style.opacity = '0';
    div.style.pointerEvents = 'none';

    document.body.appendChild(div);

    const width = div.offsetWidth;
    const height = div.offsetHeight;

    div.remove();

    return { width, height };
};

const getPosition = function ({
    target,
    actions,
    settings,
}: {
    target: HTMLElement;
    actions: CursorActionsT;
    settings: SettingsT;
}): { left: number; top: number; dir: CursorActionsT['dir'] } {
    const targetLeft = target.getBoundingClientRect().x;
    const targetTop = target.getBoundingClientRect().y;
    const { width, height } = getSizeItem({ actions, settings });
    const margin = 10;
    let leftDelta = 0;
    const topDelta = 0;
    const dir = actions.dir;

    if (actions.dir === 'left') {
        leftDelta = -target.offsetWidth / 2 - width / 2 - margin;
    }

    if (actions.dir === 'right') {
        leftDelta = target.offsetWidth / 2 + width / 2 + margin;
    }

    const resultLeft = targetLeft + target.offsetWidth / 2 - width / 2 + leftDelta;
    let resultTop = targetTop + target.offsetHeight / 2 - height / 2 + topDelta;

    if (resultTop < 20) {
        resultTop = 20;
    }

    if (resultTop + height + 20 > document.documentElement.clientHeight) {
        resultTop = document.documentElement.clientHeight - 20 - height;
    }

    return { left: resultLeft, top: resultTop, dir };
};

const observers: {
    id: string;
    observer: MutationObserver;
    documentClickHandler: (e: MouseEvent) => void;
    documentResizeHandler: () => void;
}[] = [];

const addCursorActions = function ({
    e,
    actions,
    settings,
}: {
    e: MouseEvent;
    actions: CursorActionsT;
    settings: SettingsT;
}): void {
    const cursorActions = store.getState().cursorActions.map((item) => ({ ...item }));
    const target = (e.target as HTMLElement).closest(settings.targetClassName) as HTMLElement;

    if (!target) {
        return;
    }

    const { left, top, dir } = getPosition({ target, actions, settings });

    cursorActions.push({ ...actions, position: { left, top }, dir });

    const observer = new MutationObserver((ev: MutationRecord[]) => {
        if (ev[0].removedNodes) {
            const node = ev[0].removedNodes[0];

            if (node === target) {
                observer.disconnect();

                deleteCursorActions({ id: actions._id });
            }
        }
    });

    const documentClickHandler = (ev: MouseEvent | TouchEvent) => {
        const actionsNode = document.querySelector(
            `.cursorActions__item[data-_id="${actions._id}"]`,
        );

        if (
            actionsNode &&
            target &&
            ev.target !== target &&
            !target.contains(ev.target as HTMLElement) &&
            ev.target !== actionsNode &&
            !actionsNode.contains(ev.target as HTMLElement)
        ) {
            deleteCursorActions({ id: actions._id });
        }
    };

    const documentResizeHandler = () => {
        deleteCursorActions({ id: actions._id });
    };

    observer.observe(target.parentNode!, { childList: true });

    observers.push({ id: actions._id, observer, documentClickHandler, documentResizeHandler });

    (document.addEventListener as ListenerT<MouseEvent>)('click', documentClickHandler);
    (document.addEventListener as ListenerT<TouchEvent>)('touchstart', documentClickHandler);
    document.addEventListener('changeWidthWindow', documentResizeHandler);

    dispatcher({ type: 'cursorActions', data: cursorActions });
};

const setCursorActions = function (
    actions: Omit<CursorActionsT, 'className'>,
    settings: SettingsT,
): {
    onClick: (e: MouseEvent) => void;
} {
    const props = {
        onClick: (e: MouseEvent) => {
            const storeActions = store.getState().cursorActions;

            if (storeActions.find((item) => item._id === actions._id)) {
                deleteCursorActions({ id: actions._id });
            } else {
                addCursorActions({
                    e,
                    actions,
                    settings,
                });
            }
        },
    };

    return props;
};

export { addCursorActions, deleteCursorActions, setCursorActions };
