import React from 'react';

import { CustomListenerT } from '../../global/types.ts';

import addItem from './methods/addItem.ts';
import checkLoading from './methods/checkLoading.ts';
import deleteItem from './methods/deleteItem.ts';
import getMoreItems from './methods/getMoreItems.ts';
import getParentForScroll from './methods/getParentForScroll.ts';
import getQueryForRequest from './methods/getQueryForRequest.ts';
import handlerLoaderList from './methods/handlerLoaderList.ts';
import setFilter from './methods/setFilter.ts';
import setItems from './methods/setItems.ts';
import startGetItems from './methods/startGetItems.ts';
import updateItem from './methods/updateItem.ts';
import updateItems from './methods/updateItems.ts';

import ItemsI from './types.ts';

import EditBlock from '../editBlock/EditBlock.tsx';

type ItemT = { _id: string } & Record<any, unknown>;
type ItemsT = { items: ItemT[] };

type GetItemsT = (data: { isUpdate?: boolean; isStart?: boolean }) => Promise<ItemsT>;

class Items<P extends {} = {}, S extends ItemsI['state'] = {}>
    extends EditBlock<ItemsI<P, S>['props'], ItemsI<P, S>['state']>
    implements ItemsI<P, S>
{
    constructor(props: ItemsI<P, S>['props']) {
        super(props);
        this.state = {
            isLoadingFilter: false,
        } as ItemsI<P, S>['state'];

        this.getMoreItems = this.getMoreItems.bind(this);
        this.updateItem = this.updateItem.bind(this);
        this.updateItems = this.updateItems.bind(this);
        this.getQueryForRequest = this.getQueryForRequest.bind(this);
        this.setItems = this.setItems.bind(this);
        this.getParentForScroll = this.getParentForScroll.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.deleteItem = this.deleteItem.bind(this);

        if (this.updateSocket) {
            this.updateSocket = this.updateSocket.bind(this);
        }

        this.parent = React.createRef();
    }

    stepCounter = 25;

    getQueryForRequest(
        ...data: Parameters<typeof getQueryForRequest>
    ): ReturnType<typeof getQueryForRequest> {
        return getQueryForRequest.call(this, ...data);
    }

    getMoreItems = getMoreItems;
    setItems = setItems;
    updateItems = updateItems;
    updateItem = updateItem;
    deleteItem = deleteItem;
    addItem = addItem;
    getParentForScroll = getParentForScroll;
    handlerLoaderList = handlerLoaderList;
    checkLoading = checkLoading;
    startGetItems = startGetItems;
    setFilter = setFilter;

    componentDidMount() {
        const { user } = this.props;

        startGetItems.call(this);

        if (user) {
            this.setState({ user });
        }

        if (this.updateSocket) {
            (document.addEventListener as CustomListenerT)('updateSocket', this.updateSocket);
        }
    }

    componentWillUnmount(): void {
        if (this.updateSocket) {
            (document.removeEventListener as CustomListenerT)('updateSocket', this.updateSocket);
        }
    }
}

export default Items;

export type { GetItemsT };
