import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';

import ChatCounterI from '../types.ts';

const setCounter: ChatCounterI['setCounter'] = async function () {
    const { chatId, type } = this.props;
    let query = '';
    const counterType = type === 'all' ? 'chats' : 'true';

    query += `counterType=${counterType}&`;

    if (chatId) {
        query += `id=${chatId}&`;
    }

    try {
        const response = await axios.get<{ data: { count: number } }>(
            `${process.env.REACT_APP_API}/chat?${query}`,
            {
                headers: getHeaders(),
            },
        );
        const counter = response.data?.data?.count;

        this.setState({ counter });
    } catch (error) {
        Promise.resolve();
    }
};

export default setCounter;
