import React from 'react';

import Error from '@components/error/Error.tsx';

import WalletsAccountI from '../types.ts';

const renderContent: WalletsAccountI['renderContent'] = function () {
    const { error } = this.state;
    const { device } = this.props;

    return (
        <>
            {this.renderTitle()}
            {this.renderFields()}
            <Error
                className={`popup__error ${device === 'desktop' ? '_left' : '_center'}`}
                error={error?.text}
            />
        </>
    );
};

export default renderContent;
