import { FilterQueryT } from '@global/types.ts';

import TableI, { ItemT } from '../types.ts';

import getListItems from '../../../requests/getListItems.ts';
import tables from '../static/tables.tsx';

const getItems: TableI['getItems'] = async function () {
    const { name, data } = this.props;
    const table = tables[name];

    const query = [...this.getQueryForRequest().params, { key: 'inList', value: true }];

    if (typeof table.getQuery === 'function') {
        const getQuery = table.getQuery as (data?: {}) => FilterQueryT[];

        query.push(...getQuery(data));
    }

    try {
        const { items, filter, isLimit, counter } = await getListItems<{}, ItemT>({
            url: table.url,
            query,
        });

        // setTimeout(() => {
        this.setItems({
            items,
            filter,
            counter,
            isLimit,
        });
        // }, 1000);

        return { items };
    } catch (error) {
        return Promise.reject();
    }
};

export default getItems;
