import React from 'react';

import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import Icon from '@components/icon/Icon.tsx';
import getUserName from '@functions/getUserName.ts';

import AuthButtonI from '../types.ts';

const renderPreview: AuthButtonI['renderPreview'] = function () {
    const { isListShow } = this.state;
    const { user, logoutProcess, inProfile } = this.props;
    const isAuth = user && (!logoutProcess || inProfile);

    return (
        <button
            className="authButton__inner _CLICK _FOCUS _ROW"
            onClick={() => {
                this.handlerButton.call(this);
            }}
        >
            <i className="authButton__icon">
                <Icon name="profile" />
            </i>
            <AnimateChange
                className="authButton__content"
                renderKey={isAuth ? getUserName({ user, type: 'short' }) : false}
                minWidth={isListShow ? 80 : undefined}
                maxWidth={200}
                // name="test"
            >
                {isAuth ? <>{getUserName({ user, type: 'short' })}</> : <>Личный кабинет</>}
            </AnimateChange>
        </button>
    );
};

export default renderPreview;
