import changePage from '@functions/changePage.ts';

import AuthButtonI from '../types.ts';

const handlerButton: AuthButtonI['handlerButton'] = function (
    this: AuthButtonI,
    isListShow = !this.state.isListShow,
) {
    const { user, callback, device } = this.props;

    if (!user) {
        changePage({ pageName: 'auth-login' });
    } else if (device === 'mobile') {
        changePage({ pageName: 'profile' });
    } else {
        this.setState({ isListShow });
    }

    if (typeof callback === 'function') {
        callback();
    }
};

export default handlerButton;
