import React from 'react';

import LegalNavI from './types.ts';

import renderHead from './renders/renderHead.tsx';
import renderList from './renders/renderList.tsx';

class LegalNav
    extends React.Component<LegalNavI['props'], LegalNavI['state']>
    implements LegalNavI
{
    constructor(props: LegalNavI['props']) {
        super(props);
        this.state = {};
    }

    renderHead = renderHead;
    renderList = renderList;

    render() {
        const { isReady } = this.props;

        return (
            <div className={`publicLegalNav ${isReady ? '_ready' : ''}`}>
                {this.renderHead()}
                {this.renderList()}
            </div>
        );
    }
}

export default LegalNav;
