import React from 'react';
import { connect } from 'react-redux';

import CustomHead from '@components/customHead/CustomHead.tsx';
import Pages from '@components/pages/Pages.tsx';
import checkAuth from '@functions/checkAuth.ts';
import mobMenuScrollTargetsTrigger from '@functions/mobMenuScrollTargetsTrigger.ts';
import { s } from '@functions/seo.ts';
import { StoreT } from '@global/types.ts';

import checkShowPopups from './methods/checkShowPopups.ts';
import getCurrentMenu from './methods/getCurrentMenu.ts';
import handlerMenuState from './methods/handlerMenuState.ts';

import RootI from './types.ts';

import renderBack from './renders/renderBack.tsx';
import renderChatBtn from './renders/renderChatBtn.tsx';
import renderCursorActions from './renders/renderCursorActions.tsx';
import renderCursorSupport from './renders/renderCursorSupport.tsx';
import renderPopups from './renders/renderPopups.tsx';
import renderProfileMenu from './renders/renderProfileMenu.tsx';
import renderPublicLegalNav from './renders/renderPublicLegalNav.tsx';
import renderPublicMenu from './renders/renderPublicMenu.tsx';
import renderTopBar from './renders/renderTopBar.tsx';
import pages from './static/pages.tsx';

const Styles = typeof window !== 'undefined' && require('./components/Styles.tsx').default;

class Root extends React.Component<RootI['props'], RootI['state']> implements RootI {
    parent: React.RefObject<HTMLDivElement>;

    constructor(props: RootI['props']) {
        super(props);
        this.state = {
            loadPages: {},
        };

        this.parent = React.createRef();
    }

    pages = pages;

    checkShowPopups = checkShowPopups;
    handlerMenuState = handlerMenuState;
    getCurrentMenu = getCurrentMenu;

    renderPopups = renderPopups;
    renderBack = renderBack;
    renderPublicMenu = renderPublicMenu;
    renderProfileMenu = renderProfileMenu;
    renderTopBar = renderTopBar;
    renderPublicLegalNav = renderPublicLegalNav;
    renderChatBtn = renderChatBtn;
    renderCursorSupport = renderCursorSupport;
    renderCursorActions = renderCursorActions;

    componentDidMount() {
        checkAuth({ connect: true });

        document.addEventListener('visibilitychange', () => {
            document.dispatchEvent(
                new CustomEvent('visibilityDocChange', { detail: { isActive: !document.hidden } }),
            );
        });
    }

    render() {
        const { loadPages } = this.state;
        const { rootInit } = this.props;

        return (
            <>
                {Styles && <Styles />}
                <CustomHead title="Livecargo" description="Поиск выгодных грузоперевозок">
                    {!s() && <link rel="icon" href={require('../../media/favicon.ico')} />}
                </CustomHead>
                <div className="body__content">
                    {rootInit && (
                        <>
                            {this.renderPublicMenu()}
                            {this.renderTopBar()}

                            {!s() && (
                                <>
                                    {this.renderBack()}
                                    {this.renderPopups()}
                                    {this.renderProfileMenu()}
                                    {this.renderPublicLegalNav()}
                                    {this.renderChatBtn()}
                                    {this.renderCursorSupport()}
                                    {this.renderCursorActions()}
                                </>
                            )}

                            <Pages
                                context={this}
                                pages={this.pages}
                                className="body__pages"
                                classNamePage="body__page"
                                fullHeight={true}
                                filter={(page) => !page.level || ['auth'].includes(page.name)}
                                callback={() => {
                                    mobMenuScrollTargetsTrigger();
                                }}
                                keyRender={Object.keys(loadPages).length}
                            />
                        </>
                    )}
                </div>
            </>
        );
    }
}

function mapStateToProps(state: StoreT) {
    return {
        alertPopup: state.alertPopup,
        afterRegPopup: state.afterRegPopup,
        calendarPopup: state.calendarPopup,
        filterPopup: state.filterPopup,
        walletsCardPopup: state.walletsCardPopup,
        walletsAccountPopup: state.walletsAccountPopup,
        rootInit: state.rootInit,
        user: state.user,
        storePages: state.pages,
        levels: state.levels,
        tariffsOrder: state.tariffsOrder,
        isShowPublicMenu: state.isShowPublicMenu,
        isShowProfileMenu: state.isShowProfileMenu,
        device: state.device,
        legalShortList: state.legalShortList,
        legalNavPopup: state.legalNavPopup,
        pointContactsPopup: state.pointContactsPopup,
        logoutProcess: state.logoutProcess,
        logsPopup: state.logsPopup,
        companyAmountDocPopup: state.companyAmountDocPopup,
    };
}

export default connect(mapStateToProps)(Root);
