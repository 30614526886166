import React from 'react';

import Media from '@components/media/Media.tsx';
import changePage from '@functions/changePage.ts';
import checkCurrentPage from '@functions/checkCurrentPage.ts';
import { s } from '@functions/seo.ts';

import MenuBtn from '../components/MenuBtn.tsx';

import TopBarI from '../types.ts';

const renderMobile: TopBarI['renderMobile'] = function () {
    const { isShowMenu, storePages } = this.props;

    return (
        <Media current="mobile">
            <div
                className="publicTopBar__logo _ROW _CLICK"
                onClick={() => {
                    changePage({
                        pageName:
                            checkCurrentPage({ name: 'public', pages: storePages }) ||
                            checkCurrentPage({ name: 'profile-index', pages: storePages })
                                ? 'public'
                                : 'profile-index',
                    });

                    this.handlerState(false);
                }}
            >
                {!s() && (
                    <>
                        <img
                            src={require('../../../../media/logo-icon.svg').default}
                            alt=""
                            className="publicTopBar__logoIcon"
                        />
                        <img
                            src={require('../../../../media/logo-title.svg').default}
                            alt=""
                            className="publicTopBar__logoTitle"
                        />
                    </>
                )}
            </div>

            <MenuBtn
                handlerState={this.handlerState.bind(this)}
                isShowMenu={isShowMenu}
                storePages={storePages}
            />
        </Media>
    );
};

export default renderMobile;
