import LazyPageI from '@classes/lazyPage/types.ts';

const loadPublic: LazyPageI['loadComponent'] = async function (name) {
    let Component;

    if (name === 'public-index') {
        Component = (await import('../../../../views/pages/public/components/index/Index.tsx'))
            .default;
    }

    if (name === 'public-tariffs') {
        Component = (await import('../../../../views/pages/public/components/tariffs/Tariffs.tsx'))
            .default;
    }

    if (name === 'public-order') {
        Component = (await import('../../../../views/pages/public/components/order/Order.tsx'))
            .default;
    }

    if (name === 'public-contacts') {
        Component = (
            await import('../../../../views/pages/public/components/contacts/Contacts.tsx')
        ).default;
    }

    if (name === 'public-blog') {
        Component = (await import('../../../../views/pages/public/components/blog/Blog.tsx'))
            .default;
    }

    if (name === 'public-legal') {
        Component = (await import('../../../../views/pages/public/components/legal/Legal.tsx'))
            .default;
    }

    return Component;
};

export default loadPublic;
