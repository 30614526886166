import React from 'react';

import CalendarI from '../types.ts';

import ListAbsoluteMain from '../../listAbsoluteMain/ListAbsoluteMain.tsx';

const renderContent: CalendarI['renderContent'] = function () {
    const { activeDate, currentDate, doubleCurrentDate } = this.state;
    const { init, inFilter } = this.props;

    return (
        <ListAbsoluteMain
            className="calendar__content _ITEMS"
            items={this.getBlocks()}
            renderItem={this.renderBlock.bind(this)}
            classNameItem="calendar__contentBlock"
            prop="id"
            styles={['height']}
            resizeParent={document.querySelector('.body__content') as HTMLElement}
            paramsParent={{ width: true }}
            keyRender={`${currentDate?.getTime()}${doubleCurrentDate?.getTime()}${activeDate?.getTime()}`}
            keyUpdateItem={`${currentDate?.getTime()}${doubleCurrentDate?.getTime()}${activeDate?.getTime()}`}
            allItems="number"
            currentItemKey={+this.getId(activeDate)}
            isNotParamsItem={true}
            isNotNullParentSize={true}
            callback={({ isInit, type }) => {
                if (isInit && type === 'parent' && typeof init === 'function') {
                    init();
                }
            }}
            classNames={inFilter ? ['_filter'] : []}
        />
    );
};

export default renderContent;
