import handlerLoading from '@functions/handlerLoading.ts';
import requestLogout from '@requests/logout.ts';

import AuthButtonI from '../types.ts';

const logout: AuthButtonI['logout'] = async function () {
    await handlerLoading.call(this, 'logout');

    try {
        await requestLogout();

        await handlerLoading.call(this, null);
    } catch (error) {
        await handlerLoading.call(this, null);
    }
};

export default logout;
