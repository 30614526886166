import React from 'react';

import Button from '@components/button/Button.tsx';
import Error from '@components/error/Error.tsx';
import Icon from '@components/icon/Icon.tsx';
import setSpacesInText from '@functions/setSpacesInText.ts';

import AlertI from '../types.ts';

const renderContent: AlertI['renderContent'] = function () {
    const { type, errorText, loadingKey, isImportant } = this.state;
    const content = type ? this.contents[type] : null;
    const description =
        typeof content?.description === 'function'
            ? content?.description.call(this)
            : content?.description;

    return (
        <>
            <div className="popup__title _COL _center _MOBMAX">
                <i className={`popup__titleIcon ${isImportant ? '_important' : ''}`}>
                    <Icon name="alert" />
                </i>
                <div
                    className="popup__titleText"
                    dangerouslySetInnerHTML={{ __html: content?.title || '' }}
                ></div>
                <div
                    className="popup__titleDescription"
                    dangerouslySetInnerHTML={{
                        __html: description ? setSpacesInText(description) : '',
                    }}
                ></div>
            </div>
            <div className="popup__button _minHeight">
                <Button onClick={this.save.bind(this)} loading={loadingKey === 'save'}>
                    {content?.buttonText}
                </Button>
            </div>
            <div className="popup__cancel _CLICK" onClick={this.close.bind(this)}>
                Отменить
            </div>
            <Error className="popup__error" error={errorText} />
        </>
    );
};

export default renderContent;
