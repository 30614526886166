import React from 'react';
import { connect } from 'react-redux';

import Icon from '@components/icon/Icon.tsx';

import MoreBtnI from './types.ts';

class MoreBtn extends React.Component<MoreBtnI['props'], MoreBtnI['state']> implements MoreBtnI {
    parent: MoreBtnI['parent'];

    constructor(props: MoreBtnI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { onClick } = this.props;

        return (
            <div ref={this.parent} className="more _CLICK" onClick={onClick}>
                <div className="more__icon">
                    <Icon name="more" />
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(MoreBtn);
