const contactsFields = {
    name: {
        support: 'Имя отправителя',
    },
    phone: {
        support: '+7 (___) ___-__-__',
        reg: 'phone',
    },
    entrance: {
        support: 'Подъезд',
        concat: {
            text: 'Подъезд ',
            position: 'start',
            exp: /[^\d]/gi,
        },
    },
    floor: {
        support: 'Этаж',
        concat: {
            text: 'Этаж ',
            position: 'start',
            exp: /[^\d]/gi,
        },
    },
    room: {
        support: 'Квартира',
        concat: {
            text: 'Квартира ',
            position: 'start',
            exp: /[^\d]/gi,
        },
    },
    comment: {
        support: 'Комментарий',
    },
} as const;

const contactsFieldsOrder = {
    user: ['name', 'phone'],
    info: ['entrance', 'floor', 'comment'],
} as const;

export default contactsFields;

export { contactsFieldsOrder };
