import React from 'react';

import Media from '@components/media/Media.tsx';
import MobPopup from '@components/mobPopup/MobPopup.tsx';

import LogsI from '../types.ts';

const renderMobile: LogsI['renderMobile'] = function () {
    const { windowHeight } = this.props;

    return (
        <>
            <Media current="mobile">
                <MobPopup
                    isInit={true}
                    windowHeight={windowHeight}
                    hideCallback={this.close.bind(this)}
                    name="logs"
                    initCallback={this.setInit.bind(this)}
                >
                    {this.renderContent()}
                </MobPopup>
            </Media>
        </>
    );
};

export default renderMobile;
