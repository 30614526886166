type RGBT = [number, number, number];

export default function toRgb(hex: string): RGBT | [] {
    const result = [];

    if (!hex) {
        return [];
    }

    if (hex[0] === '#') {
        hex = hex.slice(1);
    }

    result[0] = parseInt(hex.slice(0, 2), 16);
    result[1] = parseInt(hex.slice(2, 4), 16);
    result[2] = parseInt(hex.slice(4, 6), 16);

    return result as RGBT;
}
