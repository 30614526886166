import React, { ElementType } from 'react';

import Animate from '@components/animate/Animate.tsx';
import setSpacesInText from '@functions/setSpacesInText.ts';

import InfoBlockI from './types.ts';

class InfoBlock extends React.Component<InfoBlockI['props']> implements InfoBlockI {
    constructor(props: InfoBlockI['props']) {
        super(props);
        this.state = {};
    }

    render() {
        const { className = '', title, description, isShow, isStatic, icon } = this.props;
        let InfoTag: ElementType = isShow === undefined ? 'div' : Animate;
        const props = isShow === undefined ? {} : { isShow: !!isShow };

        if (isStatic) {
            InfoTag = React.Fragment;
        }

        return (
            <InfoTag className="_INFOBLOCKWRAPPER" {...props}>
                <div className={`infoBlock ${className}`}>
                    <div className="infoBlock__inner _COL">
                        {icon && <img src={icon} alt="" className="infoBlock__icon" />}
                        <div
                            className="infoBlock__title"
                            dangerouslySetInnerHTML={{ __html: setSpacesInText(title) }}
                        ></div>
                        <div
                            className="infoBlock__description"
                            dangerouslySetInnerHTML={{ __html: setSpacesInText(description) }}
                        ></div>
                    </div>
                </div>
            </InfoTag>
        );
    }
}

export default InfoBlock;
