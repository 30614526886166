import React from 'react';

import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import Icon from '@components/icon/Icon.tsx';
import { setCursorSupport } from '@functions/supportHandler.ts';

import clickHandler from './methods/clickHandler.ts';
import getCounter from './methods/getCounter.ts';

import FilterButtonI from './types.ts';

class FilterButton
    extends React.Component<FilterButtonI['props'], FilterButtonI['state']>
    implements FilterButtonI
{
    constructor(props: FilterButtonI['props']) {
        super(props);
        this.state = {};
    }

    getCounter = getCounter;
    clickHandler = clickHandler;

    componentDidMount(): void {
        this.setState({ id: `${new Date().getTime()}${Math.random() * 1000}` });
    }

    render() {
        const { id } = this.state;
        const { isShort, className = '' } = this.props;
        const counter = this.getCounter();
        const supportProps =
            isShort &&
            id &&
            setCursorSupport(
                {
                    _id: id,
                    content: 'Фильтр',
                    dir: 'bottom',
                },
                {
                    targetClassName: '.filterButton',
                    className: '_upper',
                },
            );

        return (
            <>
                <button
                    className={`filterButton _CLICK _FOCUS ${className} ${isShort ? '_short' : ''}`}
                    onClick={this.clickHandler.bind(this)}
                    type="button"
                    {...supportProps}
                >
                    <AnimateChange
                        className="filterButton__counter"
                        renderKey={counter > 0 ? counter : null}
                        styles={['width']}
                    >
                        {counter > 0 ? (
                            <>
                                <div className="filterButton__counterInner">{counter}</div>
                            </>
                        ) : (
                            <></>
                        )}
                    </AnimateChange>
                    <i className="filterButton__icon">
                        <Icon name="filter" />
                    </i>
                    {!isShort && <> Фильтры</>}
                </button>
            </>
        );
    }
}

export default FilterButton;
