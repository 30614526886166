import React from 'react';

import Button from '@components/button/Button.tsx';
import Error from '@components/error/Error.tsx';
import setSpacesInText from '@functions/setSpacesInText.ts';

import WalletsCardI from '../types.ts';

const renderContent: WalletsCardI['renderContent'] = function () {
    const { loadingKey, error } = this.state;

    return (
        <>
            <img
                src={require('../../../../media/popup-wallet-icon.svg').default}
                alt=""
                className="popup__icon"
            />

            <div className="popup__title _center _MOBMAX">
                <div className="popup__titleText">Добавление новой банковской карты</div>
                <div
                    className="popup__titleDescription"
                    dangerouslySetInnerHTML={{
                        __html: setSpacesInText(
                            'Для привязки банковской карты к аккаунту будет выполнен тестовый платеж <b>в размере 1 рубля</b>. Это необходимо для того, чтобы проверить работоспособность карты. После успешной привязки деньги <b>будут возвращены</b> обратно на карту',
                        ),
                    }}
                ></div>
            </div>
            <div className="popup__button">
                <Button onClick={this.save.bind(this)} loading={loadingKey === 'save'}>
                    Перейти на страницу оплаты
                </Button>
            </div>
            <Error className="popup__error" error={error?.text} />
        </>
    );
};

export default renderContent;
