import handlerPopup from '@functions/handlerPopup.ts';

import AlertI from '../types.ts';

const close: AlertI['close'] = function () {
    handlerPopup('alertPopup', {
        isShow: false,
        type: null,
        callback: null,
        isImportant: null,
        userName: null,
        reason: null,
        walletNumber: null,
        orderNumber: null,
    });
};

export default close;
