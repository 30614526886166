import React from 'react';

import Animate from '@components/animate/Animate.tsx';
import ProfileMenu from '@components/public/menu/Menu.tsx';

import RootI from '../types.ts';

const renderProfileMenu: RootI['renderProfileMenu'] = function () {
    const { isShowProfileMenu } = this.props;

    return (
        <Animate className="body__publicMenu" isShow={isShowProfileMenu}>
            <ProfileMenu
                isShowMenu={isShowProfileMenu}
                handlerState={this.handlerMenuState.bind(this)}
                profile={true}
            />
        </Animate>
    );
};

export default renderProfileMenu;
