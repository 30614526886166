import React from 'react';
import { connect } from 'react-redux';

import { CustomListenerT, StoreT } from '@global/types.ts';

import handlerScroll from './methods/handlerScroll.ts';
import handlerState from './methods/handlerState.ts';

import TopBarI from './types.ts';

import renderDesktop from './renders/renderDesktop.tsx';
import renderMobile from './renders/renderMobile.tsx';

class TopBar extends React.Component<TopBarI['props'], TopBarI['state']> implements TopBarI {
    constructor(props: TopBarI['props']) {
        super(props);
        this.state = {
            isScroll: false,
        };

        this.handlerScroll = this.handlerScroll.bind(this);
    }

    handlerScroll = handlerScroll;
    handlerState = handlerState;

    renderDesktop = renderDesktop;
    renderMobile = renderMobile;

    componentDidMount(): void {
        (document.addEventListener as CustomListenerT)('pageScroll', this.handlerScroll);
    }

    componentWillUnmount(): void {
        (document.removeEventListener as CustomListenerT)('pageScroll', this.handlerScroll);
    }

    render() {
        const { isScroll } = this.state;

        return (
            <>
                <div className={`publicTopBar _SECTION ${isScroll ? '_scroll' : ''}`}>
                    <div className="publicTopBar__inner _ROW">
                        {this.renderDesktop()}
                        {this.renderMobile()}
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state: StoreT) {
    return {
        user: state.user,
        storePages: state.pages,
    };
}

export default connect(mapStateToProps)(TopBar);
