import React from 'react';
import { connect } from 'react-redux';

import Error from '@components/error/Error.tsx';
import Button from '@components/button/Button.tsx';

import checkChange from './methods/checkChange.ts';
import getAllItems from './methods/getAllItems.ts';
import getBlockId from './methods/getBlockId.ts';
import getBlocks from './methods/getBlocks.ts';
import getDays from './methods/getDays.ts';
import getId from './methods/getId.ts';
import init from './methods/init.ts';
import save from './methods/save.ts';
import setDate from './methods/setDate.ts';
import setDateText from './methods/setDateText.ts';
import setNextDate from './methods/setNextDate.ts';
import setNowTime from './methods/setNowTime.ts';
import setTime from './methods/setTime.ts';

import CalendarI from './types.ts';

import renderBlock from './renders/renderBlock.tsx';
import renderContent from './renders/renderContent.tsx';
import renderHead from './renders/renderHead.tsx';
import renderTimes from './renders/renderTimes.tsx';
import days from './static/days.ts';

class Calendar
    extends React.Component<CalendarI['props'], CalendarI['state']>
    implements CalendarI
{
    parent: React.RefObject<HTMLDivElement>;
    updatedKey: any;

    constructor(props: CalendarI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    days = days;
    savedChangedDoubleDateKey = 'currentDate' as const;

    getId = getId;
    getDays = getDays;
    getBlocks = getBlocks;
    getBlockId = getBlockId;
    getAllItems = getAllItems;
    setDateText = setDateText;
    setTime = setTime;
    setDate = setDate;
    setNextDate = setNextDate;
    save = save;
    setNowTime = setNowTime;
    checkChange = checkChange;
    init = init;

    renderBlock = renderBlock;
    renderHead = renderHead;
    renderTimes = renderTimes;
    renderContent = renderContent;

    componentDidUpdate() {
        this.checkChange();
    }

    componentDidMount() {
        this.updatedKey = this.props.updatedKey;

        this.init();
    }

    render() {
        const { error } = this.state;
        const { inFilter, momentCallback } = this.props;

        return (
            <>
                <div ref={this.parent} className={`calendar ${inFilter ? '_filter' : ''}`}>
                    {this.renderHead()}
                    <div className="calendar__days _ROW">
                        {this.days.map((day) => (
                            <div className="calendar__day" key={day}>
                                {day}
                            </div>
                        ))}
                    </div>
                    {this.renderContent()}
                    {this.renderTimes()}
                    {!momentCallback && (
                        <>
                            <Error className="calendar__error" error={error} />
                            <div className="calendar__button">
                                <Button onClick={this.save.bind(this)}>Сохранить</Button>
                            </div>
                        </>
                    )}
                </div>
            </>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Calendar);
