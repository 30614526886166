import React from 'react';

import Popup from '@components/popup/Popup.tsx';
import Media from '@components/media/Media.tsx';

import FilterI from '../types.ts';

const renderDesktop: FilterI['renderDesktop'] = function () {
    return (
        <Media current="desktop">
            <Popup close={this.close.bind(this)}>
                <div className="popup__page _show">
                    <div className="popup__box _filter">{this.renderContent()}</div>
                </div>
            </Popup>
        </Media>
    );
};

export default renderDesktop;
