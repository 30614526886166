import checkCurrentPage from '@functions/checkCurrentPage.ts';

import RootI from '../types.ts';

const getCurrentMenu: RootI['getCurrentMenu'] = function () {
    const { storePages } = this.props;

    return checkCurrentPage({ name: 'public', pages: storePages })
        ? 'isShowPublicMenu'
        : 'isShowProfileMenu';
};

export default getCurrentMenu;
