import React from 'react';

import FilterI from '../types.ts';

const renderContent: FilterI['renderContent'] = function () {
    const { model = [], name } = this.state;

    const info = name ? this.info[name] : null;

    return (
        <>
            <div className="popup__boxHead">
                <div className="popup__title _notMargin">
                    <div className="popup__titleText">{info?.title}</div>
                </div>
            </div>
            <div className="popup__boxContent">
                <div className="filter">
                    {model.map((block, index) => this.renderBlock({ block, index }))}
                    {this.renderButton()}
                </div>
            </div>
        </>
    );
};

export default renderContent;
