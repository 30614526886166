import LazyPageI from '@classes/lazyPage/types.ts';

const loadProfile: LazyPageI['loadComponent'] = async function (name) {
    let Component;

    if (name === 'profile-chats') {
        Component = (await import('../../../../views/pages/profile/components/chats/Chats.tsx'))
            .default;
    }

    if (name === 'profile-clients') {
        Component = (await import('../../../../views/pages/profile/components/clients/Clients.tsx'))
            .default;
    }

    if (name === 'profile-company') {
        Component = (await import('../../../../views/pages/profile/components/company/Company.tsx'))
            .default;
    }

    if (name === 'profile-index') {
        Component = (await import('../../../../views/pages/profile/components/index/Index.tsx'))
            .default;
    }

    if (name === 'profile-operations') {
        Component = (
            await import('../../../../views/pages/profile/components/operations/Operations.tsx')
        ).default;
    }

    if (name === 'profile-orders') {
        Component = (await import('../../../../views/pages/profile/components/orders/Orders.tsx'))
            .default;
    }

    if (name === 'profile-settings') {
        Component = (
            await import('../../../../views/pages/profile/components/settings/Settings.tsx')
        ).default;
    }

    if (name === 'profile-wallets') {
        Component = (await import('../../../../views/pages/profile/components/wallets/Wallets.tsx'))
            .default;
    }

    if (name === 'profile-workers') {
        Component = (await import('../../../../views/pages/profile/components/workers/Workers.tsx'))
            .default;
    }

    return Component;
};

export default loadProfile;
