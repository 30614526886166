import React from 'react';

import Avatar from '@components/avatar/Avatar.tsx';
import Media from '@components/media/Media.tsx';
import tables from '@components/table/static/tables.tsx';
import getFormatedDate from '@functions/getFormatedDate.ts';
import getUserName from '@functions/getUserName.ts';
import setSpacesInText from '@functions/setSpacesInText.ts';
import { setCursorSupport } from '@functions/supportHandler.ts';
import LogT from '@global/models/Log.ts';

import { RenderColT } from '../../types.ts';

const renderLogs: RenderColT = function (data) {
    const log = data.item as LogT;
    const name = data.name as (typeof tables)['logs']['colsOrder'][number];

    if (name === 'name') {
        return (
            <>
                <div
                    className="profileTable__colText"
                    dangerouslySetInnerHTML={{ __html: setSpacesInText(log.content) }}
                ></div>
            </>
        );
    }

    if (name === 'holder') {
        const supportProps =
            log.holder.role &&
            setCursorSupport(
                {
                    _id: log._id,
                    content: log.holder.role === 'admin' ? 'Администратор' : 'Livecargo',
                    dir: 'bottom',
                },
                {
                    targetClassName: '.profileTable__holderAvatar',
                    // className: '_upper',
                },
            );

        const renderContent = () => (
            <>
                <div className="profileTable__colText">{getUserName({ user: log.holder })}</div>
            </>
        );

        return (
            <>
                <Media current="desktop">
                    <div className="profileTable__holder _mediumSize _ROW">
                        <div className="profileTable__holderAvatar" {...supportProps}>
                            <Avatar
                                color={log.holder.color!}
                                firstSymb={log.holder.secondName[0]}
                                secondSymb={log.holder.firstName?.[0]}
                                image={
                                    log.holder.role === 'system'
                                        ? require('../../../../media/logo-icon.svg').default
                                        : undefined
                                }
                            />
                        </div>
                        <div className="profileTable__holderContent">{renderContent()}</div>
                    </div>
                </Media>
                <Media current="mobile">{renderContent()}</Media>
            </>
        );
    }

    if (name === 'date') {
        return (
            <>
                <div className="profileTable__colText">
                    {getFormatedDate({ date: new Date(log.createdDate), type: 'full' })}
                </div>
            </>
        );
    }

    return null;
};

export default renderLogs;
