import FilterButtonI from '../types.ts';

const getCounter: FilterButtonI['getCounter'] = function () {
    const { filterQuery = [] } = this.props;
    const counters: string[] = [];

    filterQuery.forEach((block) => {
        if (!counters.includes(block.name as string)) {
            counters.push(block.name as string);
        }
    });

    const counter = counters.length;

    return counter;
};

export default getCounter;
