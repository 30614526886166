import React from 'react';

import Animate from '@components/animate/Animate.tsx';
import TopBar from '@components/public/topBar/TopBar.tsx';
import checkCurrentPage from '@functions/checkCurrentPage.ts';

import RootI from '../types.ts';

const renderTopBar: RootI['renderTopBar'] = function () {
    const { device, storePages } = this.props;
    const isShowPopup = this.checkShowPopups();

    return (
        <Animate
            className={`body__topBar ${isShowPopup ? '_hide' : ''}`}
            isShow={
                device === 'mobile' ||
                (checkCurrentPage({ name: 'public', pages: storePages }) &&
                    !checkCurrentPage({ name: 'public-order', pages: storePages }))
            }
        >
            <TopBar
                isShowMenu={this.props[this.getCurrentMenu()]}
                handlerState={this.handlerMenuState.bind(this)}
            />
        </Animate>
    );
};

export default renderTopBar;
