import axios from 'axios';

import getHeaders from '../functions/getHeaders.ts';

export default async function createId(): Promise<void | string> {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API}/id`, {
            headers: getHeaders(),
        });
        const { success, data } = response.data;

        if (success) {
            return data.id as string;
        }

        return Promise.reject();
    } catch (error) {
        return Promise.reject(error);
    }
}
