import React from 'react';

import Animate from '@components/animate/Animate.tsx';
import Icon from '@components/icon/Icon.tsx';
import Loader from '@components/loader/Loader.tsx';
import ChatCounter from '@components/profile/chats/counter/ChatCounter.tsx';
import getPage from '@functions/getPage.ts';

import AuthButtonI, { ListItemT } from '../types.ts';

const renderList: AuthButtonI['renderList'] = function () {
    const { isListShow, loadingKey } = this.state;
    const { user } = this.props;

    return (
        <Animate className="authButton__list" isShow={!!user && !!isListShow}>
            {this.getList().map((listItemName, listItemIndex) => {
                const listItem = this.list[listItemName] as ListItemT;
                const page = listItem.pageName && getPage({ name: listItem.pageName });
                const content = listItem.content || page?.content;

                return (
                    <button
                        className={`authButton__listItem _ROW _CLICK _FOCUS-INSET _${listItemName}`}
                        key={listItemName}
                        onClick={this.handlerListItem.bind(this, listItemName)}
                        type="button"
                        style={{ zIndex: listItemIndex }}
                    >
                        <Animate
                            className="authButton__listItemLoader"
                            isShow={loadingKey === listItemName}
                        >
                            <Loader />
                        </Animate>
                        <Animate
                            className="authButton__listItemIcon"
                            isShow={loadingKey !== listItemName}
                        >
                            <Icon name={listItem.icon} />
                        </Animate>
                        <div className="authButton__listItemContent">
                            {content}
                            {listItemName === 'profile-chats' && (
                                <>
                                    {user?.role === 'admin' ? (
                                        <>
                                            <ChatCounter
                                                type="all"
                                                className="authButton__listItemCounter"
                                                counterClassName="_minSize"
                                            />
                                        </>
                                    ) : (
                                        user?.chatId && (
                                            <>
                                                <ChatCounter
                                                    chatId={user.chatId}
                                                    className="authButton__listItemCounter"
                                                    counterClassName="_minSize"
                                                />
                                            </>
                                        )
                                    )}
                                </>
                            )}
                        </div>
                    </button>
                );
            })}
        </Animate>
    );
};

export default renderList;
