import React from 'react';

import { s } from '@functions/seo.ts';

import IconI from './types.ts';

import icons from './static/icons.tsx';

class Icon extends React.Component<IconI['props']> implements IconI {
    constructor(props: IconI['props']) {
        super(props);
        this.state = {};
    }

    icons = icons;

    render() {
        const { name } = this.props;

        if (s()) {
            return null;
        }

        return <div className="icon">{icons[name] ? icons[name] : null}</div>;
    }
}

export default Icon;
