import React from 'react';

import Popup from '@components/popup/Popup.tsx';
import Media from '@components/media/Media.tsx';

import AfterRegI from '../types.ts';

const renderDesktop: AfterRegI['renderDesktop'] = function () {
    return (
        <>
            <Media current="desktop">
                <Popup className="_headCenter">
                    <div className="popup__page _show">
                        <div className="popup__box _success">{this.renderContent()}</div>
                    </div>
                </Popup>
            </Media>
        </>
    );
};

export default renderDesktop;
