import React from 'react';

import Animate from '@components/animate/Animate.tsx';
import Media from '@components/media/Media.tsx';
import checkCurrentPage from '@functions/checkCurrentPage.ts';
import handlerPopup from '@functions/handlerPopup.ts';

import RootI from '../types.ts';

const renderPublicLegalNav: RootI['renderPublicLegalNav'] = function () {
    const { storePages } = this.props;

    return (
        <Media current="mobile">
            <Animate
                className="body__legalNav"
                isShow={checkCurrentPage({ name: 'public-legal', pages: storePages })}
                onClick={() => {
                    handlerPopup('legalNavPopup', { isShow: true });
                }}
            >
                Содержание
            </Animate>
        </Media>
    );
};

export default renderPublicLegalNav;
