import LazyPageI from '@classes/lazyPage/types.ts';

const loadRoot: LazyPageI['loadComponent'] = async function (name) {
    let Component;

    if (name === 'profile') {
        Component = (await import('../../../../views/pages/profile/Profile.tsx')).default;
    }

    if (name === 'public') {
        Component = (await import('../../../../views/pages/public/Public.tsx')).default;
    }

    if (name === 'order-details') {
        Component = (await import('../../../../views/pages/orderDetails/OrderDetails.tsx')).default;
    }

    if (name === 'auth') {
        Component = (await import('../../../../views/pages/auth/Auth.tsx')).default;
    }

    if (name === 'client') {
        Component = (await import('../../../../views/pages/client/Client.tsx')).default;
    }

    if (name === 'worker') {
        Component = (await import('../../../../views/pages/worker/Worker.tsx')).default;
    }

    return Component;
};

export default loadRoot;
