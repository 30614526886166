import React from 'react';

import Media from '@components/media/Media.tsx';

import TableI from '../types.ts';

const renderCols: TableI['renderCols'] = function ({ head, item }) {
    const { name, user } = this.props;
    const table = this.tables[name];

    const colsOrder =
        typeof table.colsOrder === 'function' ? table.colsOrder({ user }) : table.colsOrder;

    return (
        <>
            {colsOrder.map((colName, colIndex) => {
                const col = (table.cols as Record<typeof colName, string>)[colName];

                return (
                    <div className={`profileTable__col _${colName}`} key={colName}>
                        {head ? (
                            <>
                                <Media current="desktop">{col}</Media>
                                <Media current="mobile">
                                    {colIndex === 0 ? col : col.toLowerCase()}
                                </Media>
                                {colIndex !== colsOrder.length - 1 && (
                                    <Media current="mobile">, </Media>
                                )}
                            </>
                        ) : (
                            <>
                                {table.render.call(this, { name: colName, item: item! })}
                                {colIndex !== colsOrder.length - 1 && (
                                    <Media current="mobile">, </Media>
                                )}
                            </>
                        )}
                    </div>
                );
            })}
        </>
    );
};

export default renderCols;
