import React from 'react';

import AnimateChange from '@components/animateChange/AnimateChange.tsx';

import ErrorI from './types.ts';

class Error extends React.Component<ErrorI['props']> implements ErrorI {
    constructor(props: ErrorI['props']) {
        super(props);
        this.state = {};
    }

    render() {
        const { className = '', error, callback } = this.props;

        return (
            <AnimateChange
                className={className}
                renderKey={error}
                styles={['height']}
                callback={callback}
            >
                <div className="error">{error}</div>
            </AnimateChange>
        );
    }
}

export default Error;
