import TableI from '../types.ts';

const checkInit: TableI['checkInit'] = function () {
    const { isInit = true } = this.props;

    if (isInit && !this.isInit) {
        this.isInit = true;

        this.startGetItems();
    }
};

export default checkInit;
