import React from 'react';

import Button from '@components/button/Button.tsx';
import Icon from '@components/icon/Icon.tsx';
import handlerPopup from '@functions/handlerPopup.ts';
import setSpacesInText from '@functions/setSpacesInText.ts';

import AfterRegI from '../types.ts';

const renderContent: AfterRegI['renderContent'] = function () {
    return (
        <div className="popup__boxContent">
            <div className="popup__title _COL _center">
                <i className="popup__titleIcon">
                    <Icon name="success" />
                </i>
                <div className="popup__titleText">Поздравляем!</div>
                <div
                    className="popup__titleDescription"
                    dangerouslySetInnerHTML={{
                        __html: setSpacesInText(
                            'Вы успешно зарегистрировались в системе Livecargo. <br/>Желаем успешных заказов',
                        ),
                    }}
                ></div>
            </div>
            <div className="popup__button _minHeight">
                <Button
                    onClick={() => {
                        handlerPopup('afterRegPopup', { isShow: false });
                    }}
                >
                    Начать
                </Button>
            </div>
        </div>
    );
};

export default renderContent;
