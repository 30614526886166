import React from 'react';
import { connect } from 'react-redux';

import { StoreT } from '@global/types.ts';

import close from './methods/close.ts';
import setInit from './methods/setInit.ts';

import LogsI from './types.ts';

import renderContent from './renders/renderContent.tsx';
import renderDesktop from './renders/renderDesktop.tsx';
import renderMobile from './renders/renderMobile.tsx';

class LogsPopup extends React.Component<LogsI['props'], LogsI['state']> implements LogsI {
    parent: LogsI['parent'];

    constructor(props: LogsI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    close = close;
    setInit = setInit;

    renderDesktop = renderDesktop;
    renderMobile = renderMobile;
    renderContent = renderContent;

    render() {
        return (
            <>
                {this.renderMobile()}
                {this.renderDesktop()}
            </>
        );
    }
}

function mapStateToProps(state: StoreT) {
    return {
        windowHeight: state.windowHeight,
        levels: state.levels,
        device: state.device,
    };
}

export default connect(mapStateToProps)(LogsPopup);
