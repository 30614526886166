export default async function init() {
    if (process.env.REACT_APP_ENV !== 'local') {
        const registerWorker = async () => {
            try {
                await navigator.serviceWorker.register('/service-worker.js');

                const swListener = new BroadcastChannel('swListener');

                swListener.onmessage = () => {
                    // const data = JSON.parse(e.data);
                    // const { id, type } = data;
                    // let pageName;
                    // if (type === 'payWaiting') {
                    //     pageName = 'pays-process';
                    // } else if (type === 'payCompleted') {
                    //     pageName = 'pays-completed';
                    // } else if (type === 'newMessage') {
                    //     pageName = 'chat-inner';
                    // }
                    // if (pageName) {
                    //     changePage({ href: pageName, id });
                    // }
                };
            } catch (error) {
                return;
            }
        };

        if ('serviceWorker' in navigator) {
            window.addEventListener('load', () => {
                registerWorker();
            });
        } else {
            console.log('service worker is not supported');
        }
    }
}
