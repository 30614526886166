import React from 'react';

import Link from '@components/link/Link.tsx';
import ChatCounter from '@components/profile/chats/counter/ChatCounter.tsx';
import getNav from '@functions/getNav.ts';
import getPage from '@functions/getPage.ts';

import MenuI from '../types.ts';

const renderNav: MenuI['renderNav'] = function () {
    const { handlerState, profile, user } = this.props;

    const nav = getNav({ type: profile ? 'profile' : 'public', user: user! });

    return (
        <div className="publicMenu__nav _COL">
            {nav.map((pageName) => {
                const page = getPage({ name: pageName });
                const { name, content } = page;

                return (
                    <div className="publicMenu__navItem" key={name}>
                        <Link
                            className="publicMenu__navLink"
                            pageName={name}
                            callback={() => {
                                handlerState(false);
                            }}
                        >
                            <>
                                {name === 'profile-chats' && (
                                    <>
                                        <ChatCounter
                                            type="all"
                                            className="publicMenu__navCounter"
                                            counterClassName="_mediumSize"
                                        />
                                    </>
                                )}
                                {content}
                            </>
                        </Link>
                    </div>
                );
            })}
        </div>
    );
};

export default renderNav;
