import ItemsI from '../types.ts';

const updateItems: ItemsI['updateItems'] = function (this: ItemsI, isNotRemove = false) {
    if (!this.state.isReady) {
        return Promise.resolve();
    }

    if (this.filterTimerId) {
        clearTimeout(this.filterTimerId);
    }

    return new Promise((resolve) => {
        this.setState({ isLoadingFilter: true }, () => {
            if (this.controller) {
                this.controller.abort();
            }

            this.controller = new AbortController();

            this.filterTimerId = setTimeout(() => {
                this.setState(
                    {
                        ...(isNotRemove === true ? {} : { items: [] }),
                        counterScroll: this.stepCounter,
                        isLimit: false,
                        counterUpdate: new Date().getTime(),
                    },
                    () => {
                        if (this.getItems) {
                            this.getItems({ isUpdate: true }).then(
                                () => {
                                    setTimeout(() => {
                                        this.setState(
                                            { isLoadingFilter: false },
                                            this.loadingCallback,
                                        );
                                    }, 100);

                                    resolve();
                                },
                                () => null,
                            );
                        }
                    },
                );
            }, 300);
        });
    });
};

export default updateItems;
