import EditBlockI, { ModelT } from '../types.ts';

const checkChange: EditBlockI['checkChange'] = function (force) {
    const { deleteItems = [] } = this.state;
    const model = this.state[this.name as keyof EditBlockI['state']];
    const savedModel = this.state[this.savedName as keyof EditBlockI['state']];
    const fields: ModelT = {};

    if (model && savedModel) {
        const clearKey = (key: string) =>
            key
                .split('.')
                .filter(
                    (prop) =>
                        Number.isNaN(+prop) && !/^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i.test(prop),
                )
                .join('.');

        const allKeys = this.getAllKeys({ item: model }) as ModelT;
        const savedAllKeys = this.getAllKeys({ item: savedModel }) as ModelT;

        const clear = (value: any) => {
            if (['', null, undefined].includes(value)) {
                return '';
            }

            return value;
        };

        Object.keys(allKeys).forEach((key) => {
            if (
                (!this.checkFields || this.checkFields.includes(clearKey(key))) &&
                (clear(allKeys[key]) !== clear(savedAllKeys[key]) || force) &&
                !deleteItems.find((item) => key.includes(item))
            ) {
                fields[key] = allKeys[key];
            }
        });

        Object.keys(savedAllKeys).forEach((key) => {
            if (
                (!this.checkFields || this.checkFields.includes(clearKey(key))) &&
                (clear(allKeys[key]) !== clear(savedAllKeys[key]) || force) &&
                !deleteItems.find((item) => key.includes(item))
            ) {
                // console.log(fields[key], allKeys[key]);
                fields[key] = allKeys[key];
            }
        });
    }

    return {
        fields,
        deleteItems,
        isChange: Object.keys(fields).length > 0 || deleteItems.length > 0,
    };
};

export default checkChange;
