import React from 'react';
import { connect } from 'react-redux';

import ListAbsoluteMain from '@components/listAbsoluteMain/ListAbsoluteMain.tsx';
import { StoreT } from '@global/types.ts';

import getItems from './methods/getItems.ts';
import itemHandler from './methods/itemHandler.ts';

import CursorActionsI from './types.ts';

import renderItem from './renders/renderItem.tsx';
import actions from './static/actions.ts';

class CursorActions
    extends React.Component<CursorActionsI['props'], CursorActionsI['state']>
    implements CursorActionsI
{
    parent: CursorActionsI['parent'];

    constructor(props: CursorActionsI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    actions = actions;

    getItems = getItems;
    itemHandler = itemHandler;

    renderItem = renderItem;

    render() {
        const items = this.getItems();

        return (
            <div className="cursorActions">
                <ListAbsoluteMain
                    className="cursorActions__items"
                    items={items}
                    renderItem={this.renderItem.bind(this)}
                    classNameItem="cursorActions__item"
                    prop="_id"
                    paramsParent={{ width: true }}
                    styles={[]}
                    itemParams={[]}
                    resizeParent={document.querySelector('.body__content') as HTMLElement}
                />
            </div>
        );
    }
}

function mapStateToProps(state: StoreT) {
    return {
        cursorActions: state.cursorActions,
    };
}

export default connect(mapStateToProps)(CursorActions);
