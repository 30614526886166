import React from 'react';
import { connect } from 'react-redux';

import AuthButton from '@components/authButton/AuthButton.tsx';
import Button from '@components/button/Button.tsx';
import Icon from '@components/icon/Icon.tsx';
import Media from '@components/media/Media.tsx';
import OrderButton from '@components/profile/orderButton/OrderButton.tsx';
import { s } from '@functions/seo.ts';
import { StoreT } from '@global/types.ts';

import logout from './methods/logout.ts';

import MenuI from './types.ts';

import renderNav from './renders/renderNav.tsx';

class PublicMenu extends React.Component<MenuI['props'], MenuI['state']> implements MenuI {
    constructor(props: MenuI['props']) {
        super(props);
        this.state = {};
    }

    logout = logout;

    renderNav = renderNav;

    componentDidMount(): void {
        document.dispatchEvent(new CustomEvent('pageScroll', { detail: { scrollTop: 0 } }));
    }

    render() {
        const { loadingKey } = this.state;
        const { handlerState, profile } = this.props;

        return (
            <>
                <div className="publicMenu _SECTION">
                    <div
                        className="publicMenu__inner _COL _NOSCROLL"
                        onScroll={({ target }: { target: EventTarget }) => {
                            document.dispatchEvent(
                                new CustomEvent('pageScroll', {
                                    detail: { scrollTop: (target as HTMLElement).scrollTop },
                                }),
                            );
                        }}
                    >
                        {!s() && (
                            <img
                                src={require('../../../media/logo.svg').default}
                                alt=""
                                className="publicMenu__logo _LOGO"
                            />
                        )}
                        <div className="publicMenu__content _COL">
                            {this.renderNav()}
                            {profile ? (
                                <>
                                    <div className="publicMenu__button">
                                        <OrderButton />
                                    </div>
                                    <div className="publicMenu__button">
                                        <Button
                                            className="_white _alert"
                                            loading={loadingKey === 'logout'}
                                            onClick={this.logout.bind(this)}
                                        >
                                            Выйти из профиля
                                            <i className="_logout">
                                                <Icon name="profile-logout" />
                                            </i>
                                        </Button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <Media current="mobile">
                                        <div className="publicMenu__auth">
                                            <AuthButton callback={() => handlerState(false)} />
                                        </div>
                                    </Media>
                                </>
                            )}
                        </div>

                        <div className="publicMenu__rights">
                            © {new Date().getUTCFullYear()} — Livecargo, поиск выгодных перевозок
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state: StoreT) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(PublicMenu);
