import React from 'react';
import { connect } from 'react-redux';

import Button from '@components/button/Button.tsx';
import Icon from '@components/icon/Icon.tsx';
import { dispatcher } from '@redux/redux.ts';

import OrderButtonI from './types.ts';

class OrderButton
    extends React.Component<OrderButtonI['props'], OrderButtonI['state']>
    implements OrderButtonI
{
    constructor(props: OrderButtonI['props']) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Button
                pageName="public-order"
                callback={() => {
                    dispatcher({ type: 'isShowProfileMenu', data: false });
                }}
            >
                Создать новый заказ{' '}
                <i className="_next">
                    <Icon name="add-order" />
                </i>
            </Button>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(OrderButton);
