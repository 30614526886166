import changePage from '@functions/changePage.ts';

import AuthButtonI, { ListItemT } from '../types.ts';

const handlerListItem: AuthButtonI['handlerListItem'] = function (key) {
    const { loadingKey } = this.state;

    if (!loadingKey) {
        const listItem = this.list[key] as ListItemT;

        if (listItem.pageName) {
            changePage({ pageName: listItem.pageName });
        } else if (key === 'logout') {
            this.logout();
        }

        this.handlerButton.call(this, false);
    }
};

export default handlerListItem;
