import EditBlockI, { ModelT } from '../types.ts';

const deleteArrayItem: EditBlockI['deleteArrayItem'] = function (deleteFields) {
    this.setState((state) => {
        const newState = { ...state } as EditBlockI['state'];
        const model = { ...newState[this.name as keyof EditBlockI['state']] };
        const deleteItems = [...(newState.deleteItems || [])];

        deleteFields.forEach((key) => {
            const { model: resultModel, key: lastKey } = this.getValue({
                model,
                key,
            }) as ModelT;

            if (resultModel) {
                const index = resultModel.findIndex((item: ModelT) => item._id === lastKey);

                if (index !== -1) {
                    if (!resultModel[index].IS_TEMPLATE) {
                        deleteItems.push(key);
                    }

                    resultModel.splice(index, 1);
                }
            }
        });

        newState.updatedKey = new Date().getTime();
        newState[this.name as keyof EditBlockI['state']] = model;
        newState.deleteItems = deleteItems;

        return newState;
    });
};

export default deleteArrayItem;
