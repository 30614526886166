import React from 'react';

import Animate from '@components/animate/Animate.tsx';
import AfterRegPopup from '@components/popups/afterReg/AfterReg.tsx';
import AlertPopup from '@components/popups/alert/Alert.tsx';
import CalendarPopup from '@components/popups/calendar/Calendar.tsx';
import CompanyAmountDoc from '@components/popups/companyAmountDoc/CompanyAmountDoc.tsx';
import FilterPopup from '@components/popups/filter/Filter.tsx';
import LegalNav from '@components/popups/legalNav/LegalNav.tsx';
import LogsPopup from '@components/popups/logs/Logs.tsx';
import PointContacts from '@components/popups/pointContacts/PointContacts.tsx';
import WalletsAccountPopup from '@components/popups/walletsAccount/WalletsAccount.tsx';
import WalletsCardPopup from '@components/popups/walletsCard/WalletsCard.tsx';

import RootI from '../types.ts';

const renderPopups: RootI['renderPopups'] = function () {
    const {
        alertPopup,
        afterRegPopup,
        calendarPopup,
        filterPopup,
        walletsCardPopup,
        walletsAccountPopup,
        legalNavPopup,
        pointContactsPopup,
        logsPopup,
        companyAmountDocPopup,
    } = this.props;

    return (
        <>
            <Animate className="body__popup" isShow={!!afterRegPopup?.isShow}>
                <AfterRegPopup />
            </Animate>
            <Animate className="body__popup" isShow={!!calendarPopup?.isShow}>
                <CalendarPopup />
            </Animate>
            <Animate className="body__popup" isShow={!!walletsCardPopup?.isShow}>
                <WalletsCardPopup {...walletsCardPopup} />
            </Animate>
            <Animate className="body__popup" isShow={!!walletsAccountPopup?.isShow}>
                <WalletsAccountPopup {...walletsAccountPopup} />
            </Animate>
            <Animate className="body__popup" isShow={!!legalNavPopup?.isShow}>
                <LegalNav {...legalNavPopup} />
            </Animate>
            <Animate className="body__popup" isShow={!!pointContactsPopup?.isShow}>
                <PointContacts {...pointContactsPopup} />
            </Animate>
            <Animate className="body__popup" isShow={!!logsPopup?.isShow}>
                <LogsPopup {...logsPopup} />
            </Animate>
            <Animate className="body__popup _alert _POPUPBACK" isShow={!!alertPopup?.isShow}>
                <AlertPopup />
            </Animate>
            <Animate className="body__popup _filter _POPUPBACK" isShow={!!filterPopup?.isShow}>
                <FilterPopup />
            </Animate>
            <Animate
                className="body__popup _companyAmountDoc"
                isShow={!!companyAmountDocPopup?.isShow}
            >
                <CompanyAmountDoc />
            </Animate>
        </>
    );
};

export default renderPopups;
