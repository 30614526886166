import React from 'react';

import Animate from '@components/animate/Animate.tsx';

import RootI from '../types.ts';

const renderBack: RootI['renderBack'] = function () {
    const { isShowPublicMenu, isShowProfileMenu } = this.props;
    const isBackShow = this.checkShowPopups() || isShowPublicMenu || isShowProfileMenu;

    return <Animate className="body__back _POPUPBACK" isShow={!!isBackShow} />;
};

export default renderBack;
