import handlerPopup from '@functions/handlerPopup.ts';

import FilterButtonI from '../types.ts';

const clickHandler: FilterButtonI['clickHandler'] = function () {
    const { callback, filter, filterQuery = [], name } = this.props;

    handlerPopup('filterPopup', {
        isShow: true,
        filter,
        filterQuery,
        name,
        callback,
    });
};

export default clickHandler;
