import React from 'react';

import Table from '@components/table/Table.tsx';

import LogsI from '../types.ts';

const renderContent: LogsI['renderContent'] = function () {
    const { isInit } = this.state;
    const { modelName, modelId, device } = this.props;

    return (
        <>
            <Table
                name="logs"
                data={{ modelName, modelId }}
                isInit={device === 'mobile' ? !!isInit : true}
            />
        </>
    );
};

export default renderContent;
