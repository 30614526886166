import React from 'react';

import { monthNames } from '../../../functions/getFormatedDate.ts';

import CalendarI from '../types.ts';

import AnimateChange from '../../animateChange/AnimateChange.tsx';
import Icon from '../../icon/Icon.tsx';

const renderHead: CalendarI['renderHead'] = function () {
    const { activeDate } = this.state;

    const activeDateText =
        activeDate && `${monthNames[activeDate.getMonth()][0]} ${activeDate?.getFullYear()}`;

    return (
        <div className="calendar__head _ROW">
            <div className="calendar__headTitle">
                <AnimateChange className="calendar__headTitleInner" renderKey={activeDateText}>
                    <>{activeDateText}</>
                </AnimateChange>
            </div>
            <div className="calendar__headActions _ROW">
                <div
                    className="calendar__headAction _CLICK"
                    onClick={this.setNextDate.bind(this, 'prev')}
                >
                    <Icon name="calendar-prev" />
                </div>
                <div
                    className="calendar__headAction _CLICK"
                    onClick={this.setNextDate.bind(this, 'next')}
                >
                    <Icon name="calendar-next" />
                </div>
            </div>
        </div>
    );
};

export default renderHead;
