import axios from 'axios';

import getHeaders from '../functions/getHeaders.ts';

import { FilterBlockT, FilterQueryT } from '../global/types.ts';

type ItemT = { _id: string } & Record<any, unknown>;

type ReturnT<T = {}, I = ItemT> = {
    items: I[];
    item: any;
    filter: FilterBlockT[];
    counter?: number;
    isLimit?: boolean;
} & Omit<T, 'items' | 'item' | 'filter' | 'counter' | 'isLimit'>;

export default async function getListItems<T = {}, I = {}>({
    url,
    query = [],
}: {
    url: string;
    query: FilterQueryT[];
}): Promise<ReturnT<T, I>> {
    let queryString = '?';

    query.forEach((item) => {
        queryString += `${item.key}=${item.value}&`;
    });

    queryString = queryString.slice(0, -1);

    try {
        const response = await axios.get(`${process.env.REACT_APP_API}/${url}${queryString}`, {
            headers: getHeaders(),
        });

        if (response.data) {
            const { success, data } = response.data;

            if (success) {
                const { items, item, filter, counter, isLimit, ...otherData } = data;

                return { items, item, filter, counter, isLimit, ...otherData };
            }

            return Promise.reject(response.data);
        }

        return Promise.reject();
    } catch (error) {
        return Promise.reject(error);
    }
}
