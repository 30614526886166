import React from 'react';
import { connect } from 'react-redux';

import { StoreT } from '@global/types.ts';

import AfterRegI from './types.ts';

import renderContent from './renders/renderContent.tsx';
import renderDesktop from './renders/renderDesktop.tsx';
import renderMobile from './renders/renderMobile.tsx';

class AfterRegPopup extends React.Component<AfterRegI['props']> implements AfterRegI {
    constructor(props: AfterRegI['props']) {
        super(props);
        this.state = {};
    }

    renderContent = renderContent;
    renderDesktop = renderDesktop;
    renderMobile = renderMobile;

    render() {
        return (
            <>
                {this.renderDesktop()}
                {this.renderMobile()}
            </>
        );
    }
}

function mapStateToProps(state: StoreT) {
    return {
        windowHeight: state.windowHeight,
    };
}

export default connect(mapStateToProps)(AfterRegPopup);
