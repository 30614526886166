import React from 'react';

import Animate from '@components/animate/Animate.tsx';
import Loader from '@components/loader/Loader.tsx';

import LegalNavI from '../types.ts';

const renderHead: LegalNavI['renderHead'] = function () {
    const { shortList } = this.props;

    return (
        <div className="publicLegalNav__head">
            Содержание:
            <Animate className="publicLegalNav__headLoader" isShow={!shortList}>
                <Loader />
            </Animate>
        </div>
    );
};

export default renderHead;
