import InputI, { FieldRegsT } from '../types.ts';

const clearValue: InputI['clearValue'] = function (value) {
    const { reg, concat } = this.props;
    const regInfo = this.regs[reg as FieldRegsT];

    if (!value) {
        return '';
    }

    if (regInfo) {
        let resulValue = '';

        value.split('').forEach((char, index) => {
            if (char !== regInfo.template[index]) {
                resulValue += char;
            }
        });

        return resulValue;
    }

    if (concat && concat.exp) {
        const resultValue = value.replace(concat.exp, '');

        return resultValue;
    }

    return value;
};

export default clearValue;
