import React from 'react';

import Media from '@components/media/Media.tsx';
import Popup from '@components/popup/Popup.tsx';

import AlertI from '../types.ts';

const renderDesktop: AlertI['renderDesktop'] = function () {
    return (
        <>
            <Media current="desktop">
                <Popup className="_headCenter" close={this.close.bind(this)}>
                    <div className="popup__page _show">
                        <div className={`popup__box _alert `}>
                            <div className="popup__boxContent">{this.renderContent()}</div>
                        </div>
                    </div>
                </Popup>
            </Media>
        </>
    );
};

export default renderDesktop;
