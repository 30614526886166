import React from 'react';
import { connect } from 'react-redux';

import ListAbsoluteMain from '@components/listAbsoluteMain/ListAbsoluteMain.tsx';
import { StoreT } from '@global/types.ts';

import getItems from './methods/getItems.ts';

import CursorSupportI from './types.ts';

import renderItem from './renders/renderItem.tsx';

class CursorSupport
    extends React.Component<CursorSupportI['props'], CursorSupportI['state']>
    implements CursorSupportI
{
    parent: CursorSupportI['parent'];

    constructor(props: CursorSupportI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    getItems = getItems;

    renderItem = renderItem;

    render() {
        const items = this.getItems();

        return (
            <div className="cursorSupport">
                <ListAbsoluteMain
                    className="cursorSupport__items"
                    items={items}
                    renderItem={this.renderItem.bind(this)}
                    classNameItem="cursorSupport__item"
                    prop="_id"
                    paramsParent={{ width: true }}
                    styles={[]}
                    itemParams={[]}
                    withoutParams={true}
                    isNotParamsItem={true}
                    resizeParent={document.querySelector('.body__content') as HTMLElement}
                />
            </div>
        );
    }
}

function mapStateToProps(state: StoreT) {
    return {
        cursorSupport: state.cursorSupport,
    };
}

export default connect(mapStateToProps)(CursorSupport);
