import React from 'react';
import { connect } from 'react-redux';

import Button from '@components/button/Button.tsx';
import EditBlock from '@components/editBlock/EditBlock.tsx';
import MobPopup from '@components/mobPopup/MobPopup.tsx';
import contactsFields from '@components/order/point/static/contactsFields.ts';
import { StoreT } from '@global/types.ts';

import close from './methods/close.ts';

import PointContactsI, { FieldsT } from './types.ts';

import renderFields from './renders/renderFields.tsx';

class PointContacts
    extends EditBlock<PointContactsI['props'], PointContactsI['state']>
    implements PointContactsI
{
    parent: PointContactsI['parent'];

    constructor(props: PointContactsI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    contactsFields = contactsFields;

    close = close;

    renderFields = renderFields;

    componentDidMount(): void {
        const { point } = this.props;
        const startFields: FieldsT = {} as FieldsT;

        (Object.keys(this.contactsFields) as (keyof typeof this.contactsFields)[]).forEach(
            (key) => {
                startFields[key] = point?.contacts[key] || '';
            },
        );

        this.init({ fields: startFields }).then(() => {
            this.setState({ isInit: true });
        });
    }

    render() {
        const { isInit, model } = this.state;
        const { windowHeight, point, callback } = this.props;

        return (
            <MobPopup
                windowHeight={windowHeight}
                isInit={!!isInit}
                hideCallback={this.close.bind(this)}
            >
                <div className="popup__title">
                    <div className="popup__titleText">Контакты на точке:</div>
                    <div className="popup__titleDescription _notTop">{point?.address}</div>
                </div>
                <div className="popup__fields _ROW _pointContacts">{this.renderFields()}</div>
                <div className="popup__button">
                    <Button
                        onClick={() => {
                            callback(model as FieldsT);

                            this.close();
                        }}
                    >
                        Сохранить
                    </Button>
                </div>
            </MobPopup>
        );
    }
}

function mapStateToProps(state: StoreT) {
    return {
        windowHeight: state.windowHeight,
    };
}

export default connect(mapStateToProps)(PointContacts);
