import React from 'react';
import { connect } from 'react-redux';

import { StoreT } from '@global/types';

import CalendarI from './types.ts';

import renderDesktop from './renders/renderDesktop.tsx';
import renderMobile from './renders/renderMobile.tsx';

class CalendarPopup
    extends React.Component<CalendarI['props'], CalendarI['state']>
    implements CalendarI
{
    constructor(props: CalendarI['props']) {
        super(props);
        this.state = {
            
        };
    }

    renderDesktop = renderDesktop;
    renderMobile = renderMobile;

    render() {
        return (
            <>
                {this.renderDesktop()}
                {this.renderMobile()}
            </>
        );
    }
}

function mapStateToProps(state: StoreT) {
    return {
        calendarPopup: state.calendarPopup,
        windowHeight: state.windowHeight,
    };
}

export default connect(mapStateToProps)(CalendarPopup);
