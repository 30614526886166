import React from 'react';

import WalletsAccountI from '../types.ts';

import Input from '../../../input/Input.tsx';

const renderInput: WalletsAccountI['renderInput'] = function ({
    name,
    support,
    reg,
    regExp,
    maxLen,
    max,
}) {
    const { error } = this.state;
    const { device } = this.props;

    return (
        <Input
            className={`_minHorizPad ${device === 'mobile' ? '_grey' : ''}`}
            name={name}
            support={support}
            reg={reg}
            regExp={regExp}
            value={this.getValue({ key: name })?.value ?? undefined}
            onChange={({ value: chgValue }) => {
                this.change({ [name]: chgValue });
            }}
            error={error?.name === name}
            maxLen={maxLen}
            max={max}
        />
    );
};

export default renderInput;
