import getNav from '@functions/getNav.ts';

import AuthButtonI from '../types.ts';

import list from '../static/list.ts';

const getList: AuthButtonI['getList'] = function () {
    const { user } = this.props;

    const nav = getNav({ user: user!, type: 'profile' }) as (keyof typeof list)[];

    return ['order', ...nav, 'logout'];
};

export default getList;
