import React from 'react';

import Icon from '@components/icon/Icon.tsx';
import LoaderBlock from '@components/loaderBlock/LoaderBlock.tsx';
import { pxToRem } from '@functions/convertSize.ts';

import CursorActionsI, { ActionT } from '../types.ts';

const renderItem: CursorActionsI['renderItem'] = function ({ item }) {
    const { loadingKey } = this.state;

    return (
        <div
            className={`cursorActions__item _${item.dir}`}
            style={
                item.position && {
                    left: `${pxToRem(item.position.left)}rem`,
                    top: `${pxToRem(item.position.top)}rem`,
                }
            }
        >
            {item.items.map((action) => {
                const actionInfo = this.actions[action.key] as ActionT;
                const { name, text } = action;

                return (
                    <div
                        className={`cursorActions__itemAction _CLICK _${action.key}`}
                        key={name}
                        onClick={this.itemHandler.bind(this, { item, action })}
                    >
                        <LoaderBlock
                            className="cursorActions__itemActionLoader"
                            isShow={loadingKey === action.name}
                        />
                        <i className="cursorActions__itemActionIcon">
                            <Icon name={actionInfo.icon} />
                        </i>
                        {actionInfo.text || text}
                    </div>
                );
            })}
        </div>
    );
};

export default renderItem;
