import axios from 'axios';

import { dispatcher } from '../redux/redux.ts';
import changePage from './changePage.ts';
import getHeaders from './getHeaders.ts';
import { deleteCookie, getCookie, setCookie } from './handlerCookies.ts';
import handlerPopup from './handlerPopup.ts';
import socketConnect from './socketConnect.ts';

type PropsT = {
    id?: string;
    connect?: boolean;
};

export default async function checkAuth(settings?: PropsT): Promise<void> {
    const id = settings?.id;
    const hash = getCookie(process.env.REACT_APP_HASH);

    if (hash) {
        let response;

        try {
            response = await axios.get(`${process.env.REACT_APP_API}/user`, {
                headers: getHeaders({ id }),
            });
        } catch (error) {
            return Promise.reject(error);
        }

        if (response) {
            const { success, data } = response.data;

            if (success) {
                const { user } = data;

                await dispatcher({ type: 'user', data: user });

                localStorage.setItem('user', JSON.stringify(user));

                localStorage.removeItem('savedOrder');

                document.dispatchEvent(new CustomEvent('setUser', { detail: { user } }));

                if (settings?.connect) {
                    socketConnect(true);
                }

                return Promise.resolve();
            }

            deleteCookie(process.env.REACT_APP_HASH);

            changePage({ href: '' });
        }
    } else {
        localStorage.removeItem('user');

        await dispatcher({ type: 'user', data: null });

        if (getCookie('regHash')) {
            const regHash = getCookie('regHash');
            const regUserId = getCookie('regUserId');

            setCookie(process.env.REACT_APP_HASH, regHash as string);

            await checkAuth({ id: regUserId, connect: true });

            handlerPopup('afterRegPopup', { isShow: true });

            changePage({ href: 'profile' });

            deleteCookie('regHash');
            deleteCookie('regUserId');
        }
    }

    return Promise.resolve();
}
