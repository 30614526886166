import mobMenuScrollTargetsTrigger from '@functions/mobMenuScrollTargetsTrigger.ts';

import TopBarI from '../types.ts';

const handlerState: TopBarI['handlerState'] = function (data) {
    if (!data) {
        mobMenuScrollTargetsTrigger();
    }

    this.props.handlerState(data);
};

export default handlerState;
