import React from 'react';

import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import Button from '@components/button/Button.tsx';

import FilterI from '../types.ts';

const renderButton: FilterI['renderButton'] = function () {
    const { filterQuery = [] } = this.state;
    const { isChange } = this.checkChange();

    return (
        <AnimateChange
            className="filter__button"
            renderKey={isChange ? 1 : filterQuery.length ? 2 : null}
            styles={['height']}
        >
            {isChange ? (
                <>
                    <div className="filter__buttonInner">
                        <Button
                            onClick={() => {
                                this.send(this.getChange());
                            }}
                        >
                            Сохранить
                        </Button>
                    </div>
                </>
            ) : filterQuery.length ? (
                <>
                    <div className="filter__buttonInner">
                        <Button
                            onClick={() => {
                                this.send([]);
                            }}
                        >
                            Сбросить
                        </Button>
                    </div>
                </>
            ) : (
                <></>
            )}
        </AnimateChange>
    );
};

export default renderButton;
