import React from 'react';

import Popup from '@components/popup/Popup.tsx';
import Calendar from '@components/calendar/Calendar.tsx';
import Media from '@components/media/Media.tsx';
import handlerPopup from '@functions/handlerPopup.ts';

import CalendarI from '../types.ts';

const renderDesktop: CalendarI['renderDesktop'] = function () {
    const { calendarPopup } = this.props;
    const { startDate, callback } = calendarPopup;

    return (
        <Media current="desktop">
            <Popup
                close={() => {
                    handlerPopup('calendarPopup', { isShow: false });
                }}
            >
                <div className="popup__page _show">
                    <div className="popup__box _dateWTime">
                        <div className="popup__boxContent">
                            <div className="popup__calendar">
                                <Calendar callback={callback} startDate={startDate} />
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        </Media>
    );
};

export default renderDesktop;
