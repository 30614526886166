import React from 'react';

import Animate from '@components/animate/Animate.tsx';
import ChatBtn from '@components/chatBtn/ChatBtn.tsx';

import RootI from '../types.ts';

const renderChatBtn: RootI['renderChatBtn'] = function () {
    const { user, storePages, logoutProcess, device } = this.props;

    return (
        <Animate
            className="body__chatBtn"
            isShow={
                !logoutProcess &&
                !!user &&
                user.role !== 'admin' &&
                !storePages['profile-chats'].isShow &&
                (device === 'desktop' || !storePages.profile.isShow)
            }
        >
            <ChatBtn chatId={user?.chatId} />
        </Animate>
    );
};

export default renderChatBtn;
