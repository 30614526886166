import React from 'react';

import NotifyCounter from '@components/notifyCounter/NotifyCounter.tsx';
import { CustomListenerT } from '@global/types.ts';

import init from './methods/init.ts';
import setCounter from './methods/setCounter.ts';
import setSearch from './methods/setSearch.ts';
import updateSocket from './methods/updateSocket.ts';

import Interface from './types.ts';

class ChatCounter
    extends React.Component<Interface['props'], Interface['state']>
    implements Interface
{
    constructor(props: Interface['props']) {
        super(props);
        this.state = {
            counter: 0,
        };

        this.updateSocket = this.updateSocket.bind(this);
    }

    tryCount = 0;

    init = init;
    setCounter = setCounter;
    setSearch = setSearch;
    updateSocket = updateSocket;

    componentDidMount(): void {
        this.init();

        (document.addEventListener as CustomListenerT)('updateSocket', this.updateSocket);
    }

    componentWillUnmount(): void {
        (document.removeEventListener as CustomListenerT)('updateSocket', this.updateSocket);
    }

    render() {
        const { counter } = this.state;
        const { className, counterClassName = '' } = this.props;

        return (
            <NotifyCounter
                counter={counter}
                className={className}
                counterClassName={counterClassName}
            />
        );
    }
}

export default ChatCounter;
