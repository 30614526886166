import React from 'react';

import Media from '@components/media/Media.tsx';
import Popup from '@components/popup/Popup.tsx';

import LogsI from '../types.ts';

const renderDesktop: LogsI['renderDesktop'] = function () {
    return (
        <Media current="desktop">
            <Popup
                close={() => {
                    this.close();
                }}
            >
                <div className="popup__page _show">
                    <div className="popup__box _logs">
                        <div className="popup__boxContent">{this.renderContent()}</div>
                    </div>
                </div>
            </Popup>
        </Media>
    );
};

export default renderDesktop;
