import ChatCounterI from '../types.ts';

const setSearch: ChatCounterI['setSearch'] = function () {
    if (!this.searchTimerId) {
        this.searchTimerId = setTimeout(async () => {
            await this.setCounter();

            this.tryCount = 0;
            this.searchTimerId = undefined;
        }, 500);
    }
};

export default setSearch;
