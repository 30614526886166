const clearPhone = (phone: string, full = false) => {
    if (phone) {
        const numbersPhone = phone.toString().replace(/[^\d]/g, '');

        if (numbersPhone.length === 10) {
            if (full) {
                return `7${numbersPhone}`;
            }

            return numbersPhone;
        }

        return numbersPhone.slice(1);
    }

    return '';
};

export default function setFormatPhone(phone: string, withPlus = true, withDef = true): string {
    if (phone) {
        phone = clearPhone(phone, true);

        if (withDef) {
            let defPhone = '';

            defPhone += phone[0];
            defPhone += ' (';
            defPhone += phone.slice(1, 4);
            defPhone += ') ';
            defPhone += phone.slice(4, 7);
            defPhone += '-';
            defPhone += phone.slice(7, 9);
            defPhone += '-';
            defPhone += phone.slice(9, 11);

            phone = defPhone;
        }

        if (withPlus && phone[0] !== '+') {
            phone = `+${phone}`;
        }

        return phone;
    }

    return phone;
}
