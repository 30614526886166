import React from 'react';

import Error from '@components/error/Error.tsx';
import Popup from '@components/popup/Popup.tsx';
import Media from '@components/media/Media.tsx';

import WalletsAccountI from '../types.ts';

const renderDesktop: WalletsAccountI['renderDesktop'] = function () {
    const { error } = this.state;

    return (
        <Media current="desktop">
            <Popup
                close={() => {
                    this.close();
                }}
            >
                <div className="popup__page _show">
                    <div className="popup__box _wallets">
                        <div className="popup__boxContent">
                            {this.renderTitle()}
                            {this.renderFields()}
                            <Error className="popup__error _left" error={error?.text} />
                        </div>
                    </div>
                </div>
            </Popup>
        </Media>
    );
};

export default renderDesktop;
