import LinkI from '../types.ts';

const checkChange: LinkI['checkChange'] = function (start) {
    const { ids } = this.props;

    let isChange = false;

    if (ids && this.ids) {
        Object.keys(this.ids).forEach((key) => {
            if (ids[+key] !== this.ids[+key]) {
                isChange = true;
            }
        });
    }

    if (start) {
        isChange = true;
    }

    if (isChange) {
        if (ids) {
            this.ids = { ...ids };
        }

        this.setHref();
    }
};

export default checkChange;
