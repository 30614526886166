import { StoreT } from '@global/types';
import { store } from '@redux/redux.ts';

import checkCurrentPage from './checkCurrentPage.ts';

export default function checkShowPopupPages({
    pages = store.getState().pages,
}: {
    pages?: StoreT['pages'];
}): boolean {
    return (
        checkCurrentPage({ name: 'auth', pages }) ||
        checkCurrentPage({ name: 'order-details', pages }) ||
        checkCurrentPage({ name: 'client', pages }) ||
        checkCurrentPage({ name: 'worker', pages })
    );
}
