import React from 'react';

import { s } from '@functions/seo.ts';
import { CustomListenerT } from '@global/types.ts';

import checkItems from './methods/checkItems.ts';
import deleteItem from './methods/deleteItem.ts';
import deleteItems from './methods/deleteItems.ts';
import getItem from './methods/getItem.ts';
import handlerItems from './methods/handlerItems.ts';

import ListDynamicI, { IndexesStateItemsT } from './types.ts';

class ListDynamic<T extends {} = {}>
    extends React.Component<ListDynamicI<T>['props'], ListDynamicI['state']>
    implements ListDynamicI<T>
{
    keyUpdate: ListDynamicI['keyUpdate'];

    constructor(props: ListDynamicI<T>['props']) {
        super(props);
        this.state = {
            items: [],
        };

        this.handlerItems = this.handlerItems.bind(this);
    }

    savedPropsItems = [];
    itemsDelete = {};
    itemsNotDelete = {};

    checkItems = checkItems;
    deleteItems = deleteItems;
    deleteItem = deleteItem;
    getItem = getItem;
    handlerItems = handlerItems;

    componentDidMount() {
        const { keyUpdate } = this.props;

        this.checkItems(true);

        this.keyUpdate = keyUpdate;

        (document.addEventListener as CustomListenerT)('handlerListDynamic', this.handlerItems);
    }

    componentDidUpdate() {
        const { isDisabled } = this.state;
        const { keyUpdate, callback, disabled } = this.props;

        if (!isDisabled && !disabled) {
            this.checkItems();

            if (keyUpdate !== this.keyUpdate) {
                this.keyUpdate = keyUpdate;

                if (callback) {
                    callback({});
                }
            }
        }
    }

    componentWillUnmount() {
        (document.removeEventListener as CustomListenerT)('handlerListDynamic', this.handlerItems);
    }

    render() {
        const { items = [], isDisabled } = this.state;
        const { items: itemsProps = [], renderItem, sort, propsForRender = {} } = this.props;
        const itemsSort = sort?.(itemsProps) || itemsProps;
        const indexItems: IndexesStateItemsT = {};

        itemsSort.forEach((item, key) => {
            indexItems[this.getItem(item)] = key;
        });

        return (
            <>
                {(s() ? itemsProps : items).map((item) => {
                    const itemKey = this.getItem(item);
                    const { isShow } = item;

                    const key = indexItems[itemKey] ?? -1;
                    const isDelete = key === -1;

                    const isFirst = key === 0;
                    const isLast = key === itemsProps.length - 1;

                    if (isDelete && !isDisabled) {
                        this.deleteItem(itemKey);
                    }

                    return renderItem({
                        prevItem: !isFirst ? itemsSort[key - 1] : null,
                        nextItem: !isLast ? itemsSort[key + 1] : null,
                        item,
                        prop: this.getItem(item),
                        isShow: !!isShow && !isDelete,
                        isDelete,
                        isFirst,
                        isLast,
                        key,
                        ...propsForRender,
                        saveItems: items,
                    });
                })}
            </>
        );
    }
}

export default ListDynamic;
