import { UserT } from '@global/models/User';
import { PageNamesT } from '@global/types';

type BodyT = {
    type: 'public' | 'profile';
    user?: UserT;
};

export default function getNav({ type, user }: BodyT): PageNamesT[] {
    if (type === 'public') {
        return ['public-index', 'public-tariffs', 'public-blog', 'public-contacts', 'public-legal'];
    }

    if (user?.role === 'admin') {
        return [
            'profile-orders',
            'profile-clients',
            'profile-operations',
            'profile-chats',
            'profile-workers',
            'profile-settings',
        ];
    }

    if (user?.type === 'juristic') {
        return [
            'profile-orders',
            'profile-company',
            'profile-wallets',
            'profile-chats',
            'profile-settings',
        ];
    }

    return ['profile-orders', 'profile-wallets', 'profile-chats', 'profile-settings'];
}
