import AlertI from '../types.ts';

const contents = {
    deleteUser: {
        title: 'Подтвердите <br class="_MOBILE" />удаление пользователя',
        description(this: AlertI) {
            const { userName } = this.state;

            return `Данное действие нельзя отменить. Вы уверены, что хотите удалить пользователя <b>${userName}</b>?`;
        },
        buttonText: 'Удалить пользователя',
    },
    rejectDeleteUser: {
        title: 'Ошибка удаления пользователя',
        description(this: AlertI) {
            const { reason } = this.state;

            if (reason === 'confirm') {
                return `Нельзя удалить сотрудника Livecargo, <b>активированного</b> в системе`;
            }

            if (reason === 'orders') {
                return `Нельзя удалить пользователя Livecargo, у которого есть хотя бы <b>один заказ</b>`;
            }

            return `Нельзя удалить пользователя`;
        },
        buttonText: 'Понятно',
    },
    deleteWallet: {
        title: 'Подтвердите <br class="_MOBILE" />удаление оплаты',
        description(this: AlertI) {
            const { walletNumber } = this.state;

            return `Данное действие нельзя отменить. Вы уверены, что хотите удалить способ оплаты <b>${walletNumber}</b>?`;
        },
        buttonText: 'Удалить способ оплаты',
    },
    cancelOrder: {
        title: 'Подтвердите <br/>отмену заказа',
        description(this: AlertI) {
            const { orderNumber } = this.state;

            return `Данное действие нельзя отменить. Вы уверены, что хотите отменить&nbsp;заказ <b>№${orderNumber}</b>?`;
        },
        buttonText: 'Отменить заказ',
    },
} as const;

export default contents;
