import handlerLoading from '@functions/handlerLoading.ts';

import AlertI from '../types.ts';

const save: AlertI['save'] = async function () {
    if (typeof this.callback === 'function') {
        await handlerLoading.call(this, 'save');

        try {
            await this.callback();

            await handlerLoading.call(this, null);

            this.close();

            if (typeof this.successCallback === 'function') {
                this.successCallback();
            }
        } catch (e) {
            const error = e as { text?: string };

            Promise.resolve();

            if (error?.text) {
                this.setState({ errorText: error.text });
            }

            await handlerLoading.call(this, null);
        }
    } else {
        this.close();
    }
};

export default save;
