const actions = {
    delete: {
        icon: 'actions-delete',
    },
    edit: {
        icon: 'actions-edit',
    },
    logs: {
        icon: 'actions-logs',
        text: 'Журнал логов',
    },
} as const;

export default actions;
