import React from 'react';

import Input from '@components/input/Input.tsx';

import Interface from '../types.ts';

const renderFields: Interface['renderFields'] = function () {
    const { model } = this.state;
    const { point } = this.props;

    return (
        <>
            {(Object.keys(this.contactsFields) as (keyof typeof this.contactsFields)[]).map(
                (fieldName) => {
                    const field = this.contactsFields[fieldName];
                    const value = model?.[fieldName];
                    const isArea = fieldName === 'comment';
                    let support = field.support;

                    if (fieldName === 'name') {
                        support = 'Имя';
                    }

                    return (
                        <div
                            className={`popup__field _left _${fieldName} ${isArea ? '_area' : ''}`}
                            key={fieldName}
                        >
                            <Input
                                name={`contactsPopup-${point?._id}-${fieldName}`}
                                concat={field.concat}
                                value={value || undefined}
                                support={support}
                                onChange={({ value: newValue }) => {
                                    this.change({
                                        [fieldName]: newValue,
                                    });
                                }}
                                reg={field.reg}
                                textarea={isArea}
                                className="_grey"
                            />
                        </div>
                    );
                },
            )}
        </>
    );
};

export default renderFields;
