import EditBlockI, { ModelT } from '../types.ts';

const change: EditBlockI['change'] = function (changedFields, modelName) {
    return new Promise((resolve) => {
        this.setState((state) => {
            const newState = { ...state } as EditBlockI['state'];
            const model = JSON.parse(
                JSON.stringify(newState[(modelName || this.name) as keyof EditBlockI['state']]),
            );
            let error: EditBlockI['state']['error'];

            if (newState.error) {
                error = { ...newState.error };
            }

            Object.keys(changedFields).forEach((key) => {
                const { model: resultModel, key: lastKey } = this.getValue({
                    model,
                    key,
                }) as ModelT;

                if (resultModel) {
                    if (changedFields[key]?.FROM_ARRAY && !resultModel[lastKey]) {
                        resultModel[lastKey] = [];
                    }

                    if (Array.isArray(resultModel[lastKey])) {
                        if (Array.isArray(changedFields[key]) && changedFields[key].length === 0) {
                            resultModel[lastKey] = changedFields[key];
                        } else if (
                            typeof changedFields[key] === 'object' &&
                            changedFields[key] !== null
                        ) {
                            resultModel[lastKey].push(changedFields[key]);
                        } else {
                            const index = resultModel[lastKey].indexOf(changedFields[key]);

                            if (index === -1) {
                                resultModel[lastKey].push(changedFields[key]);
                            } else {
                                resultModel[lastKey].splice(index, 1);
                            }
                        }
                    } else {
                        resultModel[lastKey] = changedFields[key];
                    }
                }

                if (error && error.name === key) {
                    error = {};
                }
            });

            newState.updatedKey = new Date().getTime();
            newState[(modelName || this.name) as keyof EditBlockI['state']] = model;
            newState.error = error;

            return newState;
        }, resolve);
    });
};

export default change;
