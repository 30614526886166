import LazyPageI from '../types.ts';

import loadProfile from './load/profile.ts';
import loadPublic from './load/public.ts';
import loadRoot from './load/root.ts';

const loadComponent: LazyPageI['loadComponent'] = async function (name) {
    let Component;

    if (!Component) {
        Component = await loadRoot(name);
    }

    if (!Component) {
        Component = await loadPublic(name);
    }

    if (!Component) {
        Component = await loadProfile(name);
    }

    return Component;
};

export default loadComponent;
