import React from 'react';

import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import FilterButton from '@components/filterButton/FilterButton.tsx';

import TableI from '../types.ts';

import tables from '../static/tables.tsx';

const renderHead: TableI['renderHead'] = function () {
    const { counter = 0, filter, filterQuery } = this.state;
    const { name, device } = this.props;
    const table = tables[name];

    return (
        <>
            <div className="profileTable__head">
                <div className="profileTable__headInner _ROW">
                    <div className="profileTable__headTitle">
                        <b>
                            {table.title.main}:{'  '}
                        </b>
                        <AnimateChange className="profileTable__headTitleInner" renderKey={counter}>
                            <>
                                {counter} {table.title.description(counter)}
                            </>
                        </AnimateChange>
                    </div>
                    <div className="profileTable__headActions _ROW">
                        {table.renderActions()}
                        <div className="profileTable__headFilter">
                            <FilterButton
                                filter={filter}
                                filterQuery={filterQuery}
                                callback={this.setFilter.bind(this)}
                                isShort={true}
                                className={device === 'desktop' ? '_grey' : ''}
                                name={table.filterName}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default renderHead;
