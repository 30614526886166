import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import handlerLoading from '@functions/handlerLoading.ts';

import WalletsCardI from '../types.ts';

const save: WalletsCardI['save'] = async function () {
    await handlerLoading.call(this, 'save');

    try {
        const response = await axios.patch(
            `${process.env.REACT_APP_API}/user`,
            { action: 'addWallet' },
            { headers: getHeaders() },
        );
        const { success, data } = response.data;

        if (success) {
            const { url } = data;

            window.location.href = url;

            this.close();
        } else {
            const { error } = data;

            if (error) {
                this.setState({ error });
            }
        }
    } catch (error) {
        console.log(error);
    }

    await handlerLoading.call(this, null);
};

export default save;
