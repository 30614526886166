import React from 'react';
import { connect } from 'react-redux';

import ListDynamic from '@components/listDynamic/ListDynamic.tsx';
import { CustomListenerT, StoreT } from '@global/types.ts';

import checkUpdate from './methods/checkUpdate.ts';
import getClass from './methods/getClass.ts';
import getParams from './methods/getParams.ts';
import getProp from './methods/getProp.ts';
import getRenderKey from './methods/getRenderKey.ts';
import updateList from './methods/updateList.ts';

import ListAbsoluteI from './types.ts';

import renderItem from './renders/renderItem.tsx';

class List
    extends React.Component<ListAbsoluteI['props'], ListAbsoluteI['state']>
    implements ListAbsoluteI
{
    isInit?: boolean;
    keyRender?: string | number | boolean | null | undefined;

    constructor(props: ListAbsoluteI['props']) {
        super(props);
        this.state = {};

        this.getProp = this.getProp.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.getParams = this.getParams.bind(this);
        this.updateList = this.updateList.bind(this);
    }

    getProp = getProp;
    getClass = getClass;
    getParams = getParams;
    getRenderKey = getRenderKey;
    checkUpdate = checkUpdate;
    updateList = updateList;

    renderItem = renderItem;

    componentDidMount() {
        const { id } = this.props;

        if (id) {
            (document.addEventListener as CustomListenerT)(`updateListAbsolute`, this.updateList);
        }

        this.checkUpdate();
    }

    componentDidUpdate() {
        this.checkUpdate();
    }

    componentWillUnmount() {
        const { id } = this.props;

        if (id) {
            (document.removeEventListener as CustomListenerT)(
                `updateListAbsolute`,
                this.updateList,
            );
        }
    }

    render() {
        const {
            name,
            items = [],
            sort = (arr) => arr,
            propsForUpdate,
            propsForRender = {},
            callback = () => null,
            keyUpdateItem,
            isHardRemove,
            proccessCallback,
        } = this.props;

        return (
            <ListDynamic
                name={name}
                items={items}
                renderItem={this.renderItem}
                callback={({ ...props }) => {
                    if (props.isChangeLen || props.isChangeOrder || props.isUpdateItem) {
                        this.getParams({ isChangeLen: true }).then(() => {
                            if (callback) {
                                callback({ ...props });
                            }
                        });
                    } else {
                        callback({ ...props });
                    }
                }}
                prop={this.getProp()}
                sort={sort}
                propsForUpdate={propsForUpdate}
                propsForRender={propsForRender}
                keyUpdateItem={keyUpdateItem}
                isHardRemove={isHardRemove}
                proccessCallback={proccessCallback}
            />
        );
    }
}

function mapStateToProps(state: StoreT) {
    return {
        windowIsReady: state.windowIsReady as boolean,
    };
}

export default connect(mapStateToProps)(List);
