import React from 'react';
import { connect } from 'react-redux';

import MobPopup from '@components/mobPopup/MobPopup.tsx';
import LegalNav from '@components/public/legalNav/LegalNav.tsx';
import handlerPopup from '@functions/handlerPopup.ts';
import { StoreT } from '@global/types.ts';

import LegalNavI from './types.ts';

class LegalNavPopup
    extends React.Component<LegalNavI['props'], LegalNavI['state']>
    implements LegalNavI
{
    parent: LegalNavI['parent'];

    constructor(props: LegalNavI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { windowHeight, legalShortList, levels } = this.props;

        return (
            <MobPopup
                isInit={true}
                windowHeight={windowHeight}
                hideCallback={() => {
                    handlerPopup('legalNavPopup', { isShow: false });
                }}
            >
                <LegalNav shortList={legalShortList} levels={levels} isReady={!!legalShortList} />
            </MobPopup>
        );
    }
}

function mapStateToProps(state: StoreT) {
    return {
        windowHeight: state.windowHeight,
        legalShortList: state.legalShortList,
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(LegalNavPopup);
