import React from 'react';

import Avatar from '@components/avatar/Avatar.tsx';
import Media from '@components/media/Media.tsx';
import More from '@components/table/components/More.tsx';
import tables from '@components/table/static/tables.tsx';
import getUserName from '@functions/getUserName.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import setFormatPhone from '@functions/setFormatPhone.ts';
import { UserT } from '@global/models/User.ts';
import { CursorActionsItemT } from '@global/types.ts';
import { store } from '@redux/redux.ts';
import { deleteUser } from '@requests/deleteUser.ts';

import { RenderColT } from '../../types.ts';

const renderWorkers: RenderColT = function (data) {
    const client = data.item as UserT;
    const name = data.name as (typeof tables)['clients']['colsOrder'][number];

    if (name === 'name') {
        const renderContent = () => (
            <>
                <div className="profileTable__colText">{getUserName({ user: client })}</div>
            </>
        );

        return (
            <>
                <Media current="desktop">
                    <div className="profileTable__holder _ROW">
                        <div className="profileTable__holderAvatar">
                            <Avatar
                                color={client.color}
                                firstSymb={client.secondName[0]}
                                secondSymb={client.firstName?.[0]}
                            />
                        </div>
                        <div className="profileTable__holderContent">{renderContent()}</div>
                    </div>
                </Media>
                <Media current="mobile">{renderContent()}</Media>
            </>
        );
    }

    if (name === 'status') {
        let statusClass = '_mediumColor';

        if (client.status === 'offline') {
            statusClass = '_waitColor';
        }

        if (client.status === 'online') {
            statusClass = '_successColor';
        }

        return (
            <>
                <div className={`profileTable__colText ${statusClass} _NOWRAP`}>{client.statusText}</div>
            </>
        );
    }

    if (name === 'login') {
        const user = store.getState().user;
        const actions: CursorActionsItemT[] = [
            {
                key: 'delete',
                text: 'Удалить сотрудника',
                name: 'delete',
                onClick: deleteUser.bind(null, {
                    user: client,
                    id: client._id,
                    successCallback: () => {
                        document.dispatchEvent(
                            new CustomEvent('refreshTable', {
                                detail: { name: 'workers' },
                            }),
                        );
                    },
                }),
            },
        ];

        if (user?.role === 'admin') {
            actions.push({
                key: 'logs',
                name: 'logs',
                onClick: async () => {
                    handlerPopup('logsPopup', {
                        isShow: true,
                        modelName: 'users',
                        modelId: client._id,
                    });

                    return;
                },
            });
        }

        return (
            <>
                <div className={`profileTable__colText _NOWRAP`}>
                    {client.type === 'physical' ? setFormatPhone(client.phone!) : client.email}
                </div>
                <More name={`workers-${client._id}`} actions={actions} />
            </>
        );
    }

    return null;
};

export default renderWorkers;
