import axios from 'axios';

import changePage from '@functions/changePage';
import getHeaders from '@functions/getHeaders';
import { deleteCookie } from '@functions/handlerCookies';
import { dispatcher } from '@redux/redux';

export default async function logout(): Promise<void> {
    try {
        const response = await axios.put(
            `${process.env.REACT_APP_API}/user`,
            { action: 'logout' },
            { headers: getHeaders() },
        );

        const { success } = response.data;

        if (success) {
            await dispatcher({ type: 'authProcess', data: true });
            await dispatcher({ type: 'logoutProcess', data: true });

            changePage({ href: '' });
            deleteCookie(process.env.REACT_APP_HASH as string);

            localStorage.removeItem('user');

            setTimeout(() => {
                dispatcher({ type: 'isSocketConnect', data: false });
                dispatcher({ type: 'user', data: null });
                dispatcher({ type: 'logoutProcess', data: null });
            }, 500);
        }
    } catch (error) {
        return Promise.reject();
    }

    return undefined;
}
