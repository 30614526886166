import { dispatcher } from '@redux/redux.ts';

import RootI from '../types.ts';

const handlerMenuState: RootI['handlerMenuState'] = function (
    this: RootI,
    isShowMenu = !this.props[this.getCurrentMenu()],
) {
    dispatcher({
        type: this.getCurrentMenu(),
        data: isShowMenu,
    });

    if (isShowMenu) {
        document.dispatchEvent(new CustomEvent('pageScroll', { detail: { scrollTop: 0 } }));
    }
};

export default handlerMenuState;
