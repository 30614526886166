import React from 'react';

import Icon from '@components/icon/Icon.tsx';
import Link from '@components/link/Link.tsx';
import ChatCounter from '@components/profile/chats/counter/ChatCounter.tsx';

import ChatBtnI from './types.ts';

class ChatBtn extends React.Component<ChatBtnI['props'], ChatBtnI['state']> implements ChatBtnI {
    constructor(props: ChatBtnI['props']) {
        super(props);
        this.state = {};
    }

    render() {
        const { chatId } = this.props;

        return (
            <Link className="chatBtn _COL" pageName="profile-chats">
                <i className="chatBtn__icon">
                    <Icon name="profile-chat" />
                </i>
                <ChatCounter chatId={chatId} className="chatBtn__counter" />
            </Link>
        );
    }
}

export default ChatBtn;
