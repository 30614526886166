import React from 'react';

import Popup from '@components/popup/Popup.tsx';
import Media from '@components/media/Media.tsx';

import WalletsCardI from '../types.ts';

const renderDesktop: WalletsCardI['renderDesktop'] = function () {
    return (
        <Media current="desktop">
            <Popup
                close={() => {
                    this.close();
                }}
            >
                <div className="popup__page _show">
                    <div className="popup__box _walletsCard _center">
                        <div className="popup__boxContent">{this.renderContent()}</div>
                    </div>
                </div>
            </Popup>
        </Media>
    );
};

export default renderDesktop;
