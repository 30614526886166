import checkShowPopupPages from '@functions/checkShowPopupPages.ts';

import RootI from '../types.ts';

const checkShowPopups: RootI['checkShowPopups'] = function () {
    const {
        afterRegPopup,
        calendarPopup,
        walletsCardPopup,
        walletsAccountPopup,
        legalNavPopup,
        storePages,
        pointContactsPopup,
        logsPopup,
        companyAmountDocPopup,
    } = this.props;

    const isBackShow =
        afterRegPopup?.isShow ||
        calendarPopup?.isShow ||
        walletsCardPopup?.isShow ||
        walletsAccountPopup?.isShow ||
        legalNavPopup?.isShow ||
        pointContactsPopup?.isShow ||
        logsPopup.isShow ||
        companyAmountDocPopup.isShow ||
        checkShowPopupPages({ pages: storePages });

    return isBackShow;
};

export default checkShowPopups;
