import React from 'react';

import Button from '@components/button/Button.tsx';

import WalletsAccountI from '../types.ts';

const renderFields: WalletsAccountI['renderFields'] = function () {
    const { loadingKey } = this.state;

    return (
        <div className="popup__fields _ROW _walletsAccount">
            <div className="popup__field _addressatName">
                {this.renderInput({
                    name: 'amount',
                    support: 'Сумма',
                    regExp: /[^\d.]/gi,
                    max: 100_000_000,
                })}
            </div>
            <div className="popup__field _button">
                <Button onClick={this.save.bind(this)} loading={loadingKey === 'save'}>
                    Скачать счёт на оплату
                </Button>
            </div>
        </div>
    );
};

export default renderFields;
