import React from 'react';
import { connect } from 'react-redux';

import { StoreT } from '@global/types.ts';

import getList from './methods/getList.ts';
import handlerButton from './methods/handlerButton.ts';
import handlerListItem from './methods/handlerListItem.ts';
import logout from './methods/logout.ts';

import AuthButtonI from './types.ts';

import renderList from './renders/renderList.tsx';
import renderPreview from './renders/renderPreview.tsx';
import list from './static/list.ts';

class AuthButton
    extends React.Component<AuthButtonI['props'], AuthButtonI['state']>
    implements AuthButtonI
{
    constructor(props: AuthButtonI['props']) {
        super(props);
        this.state = {};
    }

    list = list;

    getList = getList;
    handlerButton = handlerButton;
    handlerListItem = handlerListItem;

    logout = logout;

    renderPreview = renderPreview;
    renderList = renderList;

    render() {
        const { isListShow } = this.state;
        const { user, className = '', logoutProcess, inProfile } = this.props;
        const isAuth = user && (!logoutProcess || inProfile);

        return (
            <>
                <div
                    className={`authButton ${isAuth ? '_auth' : ''} ${
                        isListShow ? '_showList' : ''
                    } ${className}`}
                >
                    {this.renderPreview()}
                    {this.renderList()}
                </div>
            </>
        );
    }
}

function mapStateToProps(state: StoreT) {
    return {
        user: state.user,
        logoutProcess: state.logoutProcess,
        device: state.device,
    };
}

export default connect(mapStateToProps)(AuthButton);
