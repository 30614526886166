import axios from 'axios';

import getHeaders from '@functions/getHeaders';
import getUserName from '@functions/getUserName';
import handlerPopup from '@functions/handlerPopup';
import { UserT } from '@global/models/User';

type BodyT = {
    id: string;
    force?: boolean;
};

const deleteUserRequest = async function ({ id, force }: BodyT): Promise<void> {
    let query = `id=${id}&`;

    if (force) {
        query += `force=true&`;
    }

    try {
        const response = await axios.delete(`${process.env.REACT_APP_API}/user?${query}`, {
            headers: getHeaders(),
        });

        const { success, data } = response.data;

        if (success) {
            return undefined;
        }

        return Promise.reject(data);
    } catch (error) {
        return Promise.reject();
    }
};

const deleteUser = async function ({
    id,
    force,
    user,
    successCallback,
}: BodyT & {
    user: Pick<UserT, 'firstName' | 'secondName' | 'thirdName'>;
    successCallback?: () => void;
}): Promise<void> {
    try {
        await deleteUserRequest({ id, force });

        if (!force) {
            handlerPopup('alertPopup', {
                isShow: true,
                type: 'deleteUser',
                callback: deleteUser.bind(null, { id, force: true, user }),
                userName: getUserName({ user }),
                successCallback,
            });
        }

        return undefined;
    } catch (e) {
        const error = e as { message: string; reason?: string };

        if (error.message === 'Cant delete user') {
            handlerPopup('alertPopup', {
                isShow: true,
                type: 'rejectDeleteUser',
                reason: error.reason,
                isImportant: true,
            });

            return undefined;
        }

        return Promise.reject();
    }
};

export { deleteUser, deleteUserRequest };
