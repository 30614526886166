import React from 'react';

import Animate from '@components/animate/Animate.tsx';
import Media from '@components/media/Media.tsx';
import ChatCounter from '@components/profile/chats/counter/ChatCounter.tsx';
import { StoreT } from '@global/types.ts';

import TopBarI from '../types.ts';

export default function MenuBtn({
    children,
    handlerState,
    isShowMenu,
    storePages,
}: {
    children?: React.ReactNode;
    handlerState: TopBarI['props']['handlerState'];
    isShowMenu: TopBarI['props']['isShowMenu'];
    storePages?: StoreT['pages'];
}): React.ReactElement {
    const isChatCounterShow = !!storePages?.profile.isShow && !isShowMenu;

    return (
        <div
            className={`publicTopBar__button _CLICK _ROW _menu ${isShowMenu ? '_show' : ''}`}
            onClick={() => {
                handlerState();
            }}
        >
            <Media current="mobile">
                <Animate isShow={isChatCounterShow} className="publicTopBar__buttonCounter">
                    <ChatCounter
                        type="all"
                        className="publicTopBar__buttonCounterInner"
                        counterClassName="_minSize"
                    />
                </Animate>
            </Media>
            <div className="publicTopBar__buttonIcon">
                <div className="publicTopBar__buttonIconItem" />
                <div className="publicTopBar__buttonIconItem" />
                <div className="publicTopBar__buttonIconItem" />
            </div>
            {children}
        </div>
    );
}
