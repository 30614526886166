import LazyPage from '@classes/lazyPage/LazyPage.tsx';

import RootI from '../types.ts';

const Profile =
    process.env.REACT_APP_SEO === 'true' && require('../../pages/profile/Profile.tsx').default;
const Public =
    process.env.REACT_APP_SEO === 'true' && require('../../pages/public/Public.tsx').default;
const OrderDetails =
    process.env.REACT_APP_SEO === 'true' &&
    require('../../pages/orderDetails/OrderDetails.tsx').default;
const Auth = process.env.REACT_APP_SEO === 'true' && require('../../pages/auth/Auth.tsx').default;
const Client =
    process.env.REACT_APP_SEO === 'true' && require('../../pages/client/Client.tsx').default;
const Worker =
    process.env.REACT_APP_SEO === 'true' && require('../../pages/worker/Worker.tsx').default;

const pages = {
    public: {
        render(this: RootI) {
            return new LazyPage({ context: this }).renderPage('public', Public);
        },
    },
    profile: {
        render(this: RootI) {
            return new LazyPage({ context: this }).renderPage('profile', Profile);
        },
    },
    auth: {
        render(this: RootI) {
            return new LazyPage({ context: this }).renderPage('auth', Auth);
        },
    },
    'order-details': {
        render(this: RootI) {
            return new LazyPage({ context: this }).renderPage('order-details', OrderDetails);
        },
    },
    client: {
        render(this: RootI) {
            return new LazyPage({ context: this }).renderPage('client', Client);
        },
    },
    worker: {
        render(this: RootI) {
            return new LazyPage({ context: this }).renderPage('worker', Worker);
        },
    },
} as const;

export default pages;
