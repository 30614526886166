import TopBarI from '../types.ts';

const handlerScroll: TopBarI['handlerScroll'] = function ({ detail: { scrollTop } }) {
    const stopValue = 32;

    if (!scrollTop) {
        this.setState({ isScroll: false });
    } else {
        if (scrollTop >= stopValue && !this.state.isScroll) {
            this.setState({ isScroll: true });
        }

        if (scrollTop < stopValue && this.state.isScroll) {
            this.setState({ isScroll: false });
        }
    }
};

export default handlerScroll;
