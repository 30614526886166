import React from 'react';

import LazyImage from '@components/lazyImage/lazyImage.tsx';
import toRgb from '@functions/toRgb.ts';

import Interface from './types.ts';

class Avatar extends React.Component<Interface['props'], Interface['state']> implements Interface {
    parent: Interface['parent'];

    constructor(props: Interface['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { firstSymb, secondSymb, color, image } = this.props;
        const rgbColor = toRgb(color);

        return (
            <div ref={this.parent} className="avatar">
                <div
                    className="avatar__inner"
                    style={{ background: `rgba(${rgbColor.join(', ')}, .12)` }}
                >
                    {image ? (
                        <>
                            <LazyImage src={image} className="avatar__image" />
                        </>
                    ) : (
                        <>
                            <div className="avatar__text _ROW" style={{ color }}>
                                {firstSymb?.toUpperCase()}
                                {secondSymb?.toUpperCase()}
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export default Avatar;
