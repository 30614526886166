import handlerLoading from '@functions/handlerLoading.ts';
import { dispatcher } from '@redux/redux.ts';
import requestLogout from '@requests/logout.ts';

import MenuI from '../types.ts';

const logout: MenuI['logout'] = async function () {
    await handlerLoading.call(this, 'logout');

    try {
        await requestLogout();

        dispatcher({ type: 'isShowProfileMenu', data: false });
    } catch (error) {
        Promise.resolve();
    }

    await handlerLoading.call(this, null);
};

export default logout;
