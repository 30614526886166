import React from 'react';

import Animate from '@components/animate/Animate.tsx';
import AnimateChange from '@components/animateChange/AnimateChange.tsx';

import Interface from './types.ts';

class NotifyCounter
    extends React.Component<Interface['props'], Interface['state']>
    implements Interface
{
    constructor(props: Interface['props']) {
        super(props);
        this.state = {};
    }

    render() {
        const { counter, className, counterClassName = '' } = this.props;

        return (
            <Animate className={className} isShow={counter > 0}>
                <div className={`notifyCounter ${counterClassName}`}>
                    <AnimateChange
                        className="notifyCounter__content"
                        renderKey={counter || undefined}
                        styles={['width']}
                        classNames={counterClassName ? [counterClassName] : undefined}
                    >
                        <>{counter}</>
                    </AnimateChange>
                </div>
            </Animate>
        );
    }
}

export default NotifyCounter;
