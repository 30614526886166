import React from 'react';

import Icon from '@components/icon/Icon';
import Media from '@components/media/Media.tsx';
import TopBar from '@components/public/topBar/TopBar.tsx';

import PopupI from './types.ts';

class Popup extends React.Component<PopupI['props']> implements PopupI {
    parent: React.RefObject<HTMLDivElement>;

    constructor(props: PopupI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { children, close, className = '' } = this.props;

        return (
            <>
                <div className={`popup ${className}`}>
                    <Media current="desktop">
                        <div className="popup__head _ROW">
                            <img
                                src={require('../../media/logo.svg').default}
                                alt=""
                                className="popup__logo"
                            />
                            <button
                                className="popup__close _FOCUS _FOCUS-CENTER _ROW _CLICK"
                                onClick={close}
                            >
                                Закрыть
                                <div className="popup__closeBtn _COL">
                                    <i className="popup__closeBtnIcon">
                                        <Icon name="close" />
                                    </i>
                                </div>
                            </button>
                        </div>
                    </Media>
                    <Media current="mobile">
                        <div className="popup__head">
                            <TopBar isShowMenu={true} handlerState={() => close!()} />
                        </div>
                    </Media>

                    <div className="popup__inner _COL">{children}</div>
                </div>
            </>
        );
    }
}

export default Popup;
