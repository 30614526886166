import React from 'react';
import { connect } from 'react-redux';

import EditBlock from '@components/editBlock/EditBlock.tsx';
import { StoreT } from '@global/types.ts';

import close from './methods/close.ts';
import save from './methods/save.ts';

import WalletsCardI from './types.ts';

import renderContent from './renders/renderContent.tsx';
import renderDesktop from './renders/renderDesktop.tsx';
import renderMobile from './renders/renderMobile.tsx';

class WalletsCardPopup
    extends EditBlock<WalletsCardI['props'], WalletsCardI['state']>
    implements WalletsCardI
{
    parent: React.RefObject<HTMLDivElement>;

    constructor(props: WalletsCardI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    name = 'fields';

    savedName = 'savedFields';

    save = save;
    close = close;

    renderContent = renderContent;
    renderDesktop = renderDesktop;
    renderMobile = renderMobile;

    componentDidMount() {
        this.init({ fields: {} });
    }

    render() {
        return (
            <>
                {this.renderDesktop()}
                {this.renderMobile()}
            </>
        );
    }
}

function mapStateToProps(state: StoreT) {
    return {
        windowHeight: state.windowHeight,
    };
}

export default connect(mapStateToProps)(WalletsCardPopup);
