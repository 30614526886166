import ChatCounterI from '../types.ts';

const updateSocket: ChatCounterI['updateSocket'] = function ({ detail: { name, data } }) {
    const { type, chatId } = this.props;

    if (name === 'chat' && data && data.updateCounter) {
        const { id } = data;

        if (type === 'all' || id === chatId) {
            this.tryCount += 1;

            this.setSearch();
        }
    }
};

export default updateSocket;
