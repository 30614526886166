import React from 'react';
import { connect } from 'react-redux';

import { CustomListenerT, StoreT } from '@global/types.ts';

import checkInit from './methods/checkInit.ts';
import getItems from './methods/getItems.ts';
import refreshTable from './methods/refreshTable.ts';

import TableI from './types.ts';

import Items from '../items/Items.tsx';
import renderCols from './renders/renderCols.tsx';
import renderContent from './renders/renderContent.tsx';
import renderHead from './renders/renderHead.tsx';
import renderRow from './renders/renderRow.tsx';
import tables from './static/tables.tsx';

class Table extends Items<TableI['props'], TableI['state']> implements TableI {
    parent: TableI['parent'];

    constructor(props: TableI['props']) {
        super(props);
        this.state = {};

        this.refreshTable = this.refreshTable.bind(this);

        this.parent = React.createRef();
    }

    stepCounter = 15;
    tables = tables;
    classNameItem = '.profileTable__row';

    checkInit = checkInit;
    getItems = getItems;
    refreshTable = refreshTable;

    renderHead = renderHead;
    renderContent = renderContent;
    renderRow = renderRow;
    renderCols = renderCols;

    componentDidMount(): void {
        const { stepCounter } = this.props;

        if (stepCounter) {
            this.stepCounter = stepCounter;
        }

        this.checkInit();

        (document.addEventListener as CustomListenerT)('refreshTable', this.refreshTable);
    }

    componentDidUpdate(): void {
        this.checkInit();
    }

    componentWillUnmount(): void {
        (document.removeEventListener as CustomListenerT)('refreshTable', this.refreshTable);
    }

    render() {
        const { name, user } = this.props;

        return (
            <div
                ref={this.parent}
                className={`profileTable _${name} ${user?.role === 'admin' ? '_admin' : ''}`}
            >
                {this.renderHead()}
                {this.renderContent()}
            </div>
        );
    }
}

function mapStateToProps(state: StoreT) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(Table);
