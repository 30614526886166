import React from 'react';

import Animate from '@components/animate/Animate.tsx';
import ListAbsoluteMain from '@components/listAbsoluteMain/ListAbsoluteMain.tsx';
import Loader from '@components/loader/Loader.tsx';
import { d } from '@functions/seo.ts';

import getItems from './methods/getItems.ts';

import AnimateChangeI from './types.ts';

import renderItem from './renders/renderItem.tsx';

class AnimateChange extends React.Component<AnimateChangeI['props']> implements AnimateChangeI {
    parent: AnimateChangeI['parent'];

    constructor(props: AnimateChangeI['props']) {
        super(props);
        this.state = {};

        this.getItems = this.getItems.bind(this);
        this.renderItem = this.renderItem.bind(this);

        this.parent = React.createRef();
    }

    childrens = {};

    getItems = getItems;

    renderItem = renderItem;

    render() {
        const {
            prop = 'key',
            className = '',
            isNotParams = false,
            renderKey,
            withoutParams,
            styles,
            callback,
            minWidth,
            maxWidth,
            name,
            withLoader,
            classNames,
        } = this.props;

        const items = this.getItems();

        // console.log(this.getItems());

        return (
            <>
                <ListAbsoluteMain
                    className={`animateChange ${className} _parent`}
                    items={items}
                    renderItem={this.renderItem}
                    classNameItem={`animateChange__item ${className}`}
                    prop={prop}
                    styles={styles}
                    paramsParent={{ width: 'auto', height: 'auto' }}
                    keyRender={renderKey}
                    isNotParams={isNotParams}
                    isNotParamsItem={true}
                    withoutParams={withoutParams}
                    callback={callback}
                    isSmoothShow={true}
                    minWidth={minWidth}
                    maxWidth={maxWidth}
                    resizeParent={d.querySelector('body') as HTMLElement}
                    name={name}
                    classNames={classNames}
                    renderChildren={(inner: React.ReactElement) => (
                        <>
                            {inner}
                            {withLoader && (
                                <Animate
                                    className="animateChange__loader"
                                    isShow={items.length === 0}
                                >
                                    <Loader />
                                </Animate>
                            )}
                        </>
                    )}
                />
            </>
        );
    }
}

export default AnimateChange;
