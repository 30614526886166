import { PopupsT } from '../global/types.ts';

import { dispatcher } from '../redux/redux.ts';

export default function handlerPopup(
    name: PopupsT,
    { ...props }: { isShow: boolean } & Record<any, unknown>,
): void {
    dispatcher({ type: name, data: props });

    document.dispatchEvent(new CustomEvent('popupChange', { detail: { isShow: props.isShow } }));
}
