import getRealParams from '@functions/getRealParams.ts';

import ListScrollI from '../types.ts';

const checkStateOfLessItems: ListScrollI['checkStateOfLessItems'] = function () {
    const { lengthCurrent, maxCounter, direction, classNamesForParams = [] } = this.props;

    const update = () => {
        this.lengthCurrent = lengthCurrent;

        this.handlerScroll(true);
    };

    if (this.parent) {
        const query = {
            parent: this.parent,
            width: this.parent.offsetWidth,
            height: this.parent.offsetHeight,
            isClearStyleParent: true,
            isClearStyles: true,
            classNames: ['_parentForce', ...classNamesForParams],
            // isNotRemove: true,
        };

        const params = getRealParams(query);
        const { width, height, scrollWidth, scrollHeight } = params;
        const sizeScroll =
            direction === 'horizontal'
                ? (scrollWidth as number) - (width as number)
                : (scrollHeight as number) - (height as number);

        if (sizeScroll <= 0 && lengthCurrent < maxCounter) {
            update();
        }
    }
};

export default checkStateOfLessItems;
