import blurAll from '@functions/blurAll.ts';
import { FilterBlockT, FilterQueryT, ListenerT } from '@global/types.ts';

import FilterI from '../types.ts';

const startInit: FilterI['startInit'] = function () {
    const { filterPopup } = this.props;
    const filter: FilterQueryT[] = JSON.parse(JSON.stringify(filterPopup.filter || []));
    const { filterQuery = [] } = filterPopup;

    filterQuery.forEach(({ key, value }) => {
        const blockName = ['startDate', 'endDate'].includes(key as string) ? 'date' : key;
        const block = filter.find((item) => item.name === blockName) as FilterBlockT;

        if (block) {
            if (block.name === 'date') {
                const resultProp = key === 'startDate' ? 'currentDate' : 'doubleCurrentDate';

                block[resultProp] = value as string;
            } else if (block.type === 'checkbox') {
                if (!block.value) {
                    block.value = [];
                }

                (block.value as (string | boolean)[]).push(value);
            } else {
                block.value = value;
            }
        }
    });

    this.init({ fields: filter });

    this.setState({
        name: filterPopup.name,
        filterQuery: [...filterQuery],
        isSizeInit: !filter.find((item) => (item as FilterBlockT).type === 'date'),
    });

    (document.addEventListener as ListenerT<KeyboardEvent>)('keydown', this.handlerKey);

    blurAll();
};

export default startInit;
