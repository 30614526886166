import React from 'react';

import Media from '@components/media/Media.tsx';

import TableI from '../types.ts';

const renderRow: TableI['renderRow'] = function ({ item }) {
    return (
        <div className="profileTable__row _ROW _ITEM">
            <Media current="desktop">{this.renderCols({ item })}</Media>
            <Media current="mobile">
                <div className="profileTable__rowBlock _head">
                    {this.renderCols({ head: true })}
                </div>
                <div className="profileTable__rowBlock _content">{this.renderCols({ item })}</div>
            </Media>
        </div>
    );
};

export default renderRow;
