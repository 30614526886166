import ChatCounterI from '../types.ts';

const init: ChatCounterI['init'] = async function () {
    const { counter } = this.props;

    if (typeof counter === 'number') {
        this.setState({ counter });
    } else {
        await this.setCounter();
    }
};

export default init;
