import React from 'react';
import { connect } from 'react-redux';

import { StoreT } from '@global/types.ts';

import MediaI from './types.ts';

class Media extends React.Component<MediaI['props'], MediaI['state']> implements MediaI {
    constructor(props: MediaI['props']) {
        super(props);
        this.state = {};
    }

    render() {
        const { device, current, children, user, forUser, forAdmin } = this.props;

        if (
            (current === 'desktop' && device === 'mobile') ||
            (current === 'mobile' && device === 'desktop') ||
            (forUser === true && !user) ||
            (forUser === false && user) ||
            (forAdmin === true && user?.role !== 'admin') ||
            (forAdmin === false && user?.role === 'admin')
        ) {
            return <></>;
        }

        return <>{children}</>;
    }
}

function mapStateToProps(state: StoreT) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(Media);
