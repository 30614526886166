import { PageGetCondT, PageGetRedirectT, StoreT } from '../global/types.ts';

const pages = [
    {
        links: ['', undefined],
        name: 'public',
    },
    {
        links: ['', undefined],
        name: 'public-index',
        content: 'Главная',
        parentName: 'public',
        level: 1,
        group: 'public',
    },
    {
        links: ['tariffs'],
        name: 'public-tariffs',
        content: 'Тарифы',
        parentName: 'public',
        level: 1,
        group: 'public',
    },
    {
        links: ['blog'],
        name: 'public-blog',
        content: 'Блог',
        parentName: 'public',
        level: 1,
        group: 'public',
    },
    {
        links: [],
        name: 'public-blog-inner',
        level: 2,
        parentName: 'public-blog',
    },
    {
        links: ['contacts'],
        name: 'public-contacts',
        content: 'Контакты',
        parentName: 'public',
        level: 1,
        group: 'public',
    },
    {
        links: ['legal'],
        name: 'public-legal',
        content: 'Правовая информация',
        parentName: 'public',
        level: 1,
        group: 'public',
    },
    {
        links: [],
        name: 'public-legal-inner',
        level: 2,
        parentName: 'public-legal',
    },
    {
        links: ['order'],
        name: 'public-order',
        parentName: 'public',
        level: 1,
        group: 'public',
    },
    {
        links: [],
        name: 'public-order-inner',
        level: 2,
        parentName: 'public-order',
    },
    {
        links: ['profile'],
        name: 'profile',
        getCond({ user, authProcess }: StoreT): PageGetCondT {
            return { condition: !!user || !!authProcess };
        },
        getRedirect(): PageGetRedirectT {
            return { name: 'public' };
        },
    },
    {
        links: ['orders'],
        name: 'profile-orders',
        level: 1,
        parentName: 'profile',
        content: 'Заказы',
        group: 'profile',
    },
    {
        links: ['company'],
        name: 'profile-company',
        level: 1,
        parentName: 'profile',
        content: 'Компания',
        group: 'profile',
        getCond({ user }: StoreT): PageGetCondT {
            return { condition: !!user && user.role !== 'admin' && user.type === 'juristic' };
        },
        getRedirect(): PageGetRedirectT {
            return { name: 'profile' };
        },
    },
    {
        links: ['wallets'],
        name: 'profile-wallets',
        level: 1,
        parentName: 'profile',
        content: 'Оплата',
        group: 'profile',
        getCond({ user }: StoreT): PageGetCondT {
            return { condition: !!user && user.role !== 'admin' };
        },
        getRedirect(): PageGetRedirectT {
            return { name: 'profile' };
        },
    },
    {
        links: ['', undefined],
        name: 'profile-wallets-index',
        level: 2,
        parentName: 'profile-wallets',
    },
    {
        links: ['operations'],
        name: 'profile-wallets-operations',
        level: 2,
        parentName: 'profile-wallets',
    },
    {
        links: ['reports'],
        name: 'profile-wallets-reports',
        level: 2,
        parentName: 'profile-wallets',
    },
    {
        links: ['clients'],
        name: 'profile-clients',
        level: 1,
        parentName: 'profile',
        group: 'profile',
        content: 'Клиенты',
        getCond({ user }: StoreT): PageGetCondT {
            return { condition: user?.role === 'admin' };
        },
        getRedirect(): PageGetRedirectT {
            return { name: 'profile' };
        },
    },
    {
        links: ['operations'],
        name: 'profile-operations',
        level: 1,
        parentName: 'profile',
        group: 'profile',
        content: 'Операции',
        getCond({ user }: StoreT): PageGetCondT {
            return { condition: user?.role === 'admin' };
        },
        getRedirect(): PageGetRedirectT {
            return { name: 'profile' };
        },
    },
    {
        links: ['chats'],
        name: 'profile-chats',
        level: 1,
        parentName: 'profile',
        group: 'profile',
        content: 'Поддержка',
    },
    {
        links: [undefined, ''],
        name: 'profile-chats-index',
        level: 2,
        parentName: 'profile-chats',
    },
    {
        links: [],
        name: 'profile-chats-inner',
        level: 2,
        parentName: 'profile-chats',
    },
    {
        links: ['workers'],
        name: 'profile-workers',
        level: 1,
        parentName: 'profile',
        content: 'Сотрудники',
        group: 'profile',
        getCond({ user }: StoreT): PageGetCondT {
            return { condition: user?.role === 'admin' };
        },
        getRedirect(): PageGetRedirectT {
            return { name: 'profile' };
        },
    },
    {
        links: ['', undefined],
        name: 'profile-index',
        level: 1,
        parentName: 'profile',
        content: 'Главная',
        group: 'profile',
    },
    {
        links: ['settings'],
        name: 'profile-settings',
        level: 1,
        parentName: 'profile',
        content: 'Настройки',
        group: 'profile',
    },
    {
        links: ['auth'],
        name: 'auth',
        isPopup: true,
        getCond({ user, authProcess }: StoreT): PageGetCondT {
            return { condition: !user || !!authProcess };
        },
        getRedirect(): PageGetRedirectT {
            return { name: 'profile' };
        },
        mainPage(): string {
            return localStorage.getItem('savedOrder') ? 'public-order' : 'public-index';
        },
    },
    {
        links: ['', undefined],
        name: 'auth-login',
        level: 1,
        parentName: 'auth',
    },
    {
        links: ['registration'],
        name: 'auth-registration',
        level: 1,
        parentName: 'auth',
    },
    {
        links: ['success'],
        name: 'auth-success',
        level: 1,
        parentName: 'auth',
    },
    {
        links: ['client'],
        name: 'client',
        isPopup: true,
        mainPage: 'profile-clients',
        getCond({ user }: StoreT): PageGetCondT {
            return { condition: user?.role === 'admin' };
        },
        getRedirect(): PageGetRedirectT {
            return { name: 'profile-clients' };
        },
    },
    {
        links: ['worker'],
        name: 'worker',
        isPopup: true,
        mainPage({ user }: StoreT) {
            return user?.role === 'admin' ? 'profile-workers' : 'profile-company';
        },
        getCond({ user }: StoreT): PageGetCondT {
            return { condition: !!user };
        },
        getRedirect(): PageGetRedirectT {
            return { name: 'profile' };
        },
    },
    {
        links: ['order-details'],
        name: 'order-details',
        isPopup: true,
        mainPage: 'profile-orders',
        getCond({ user }: StoreT): PageGetCondT {
            return { condition: !!user };
        },
        getRedirect(): PageGetRedirectT {
            return { name: 'profile-orders' };
        },
    },
    {
        links: [],
        name: 'order-details-inner',
        level: 1,
        parentName: 'order-details',
    },
] as const;

export default pages;
