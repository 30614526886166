import React from 'react';

import AddButton from '@components/addButton/AddButton.tsx';
import Media from '@components/media/Media.tsx';
import changePage from '@functions/changePage.ts';
import { FilterQueryT, StoreT } from '@global/types.ts';

import getEndText from '../../../functions/getEndText.ts';

import renderClients from '../renders/colRenders/renderClients.tsx';
import renderLogs from '../renders/colRenders/renderLogs.tsx';
import renderOperations from '../renders/colRenders/renderOperations.tsx';
import renderWorkers from '../renders/colRenders/renderWorkers.tsx';

const tables = {
    operations: {
        title: {
            main: 'За всё время',
            description(counter: number): string {
                return getEndText(counter, ['операция', 'операции', 'операций']);
            },
        },
        cols: {
            date: 'Дата',
            holder: 'Контрагент',
            description: 'Назначение платежа',
            amount: 'Сумма',
        },
        colsOrder: ({ user }: { user: StoreT['user'] }) => {
            const order = ['date', 'description', 'amount'];

            if (user?.role === 'admin') {
                order.splice(1, 0, 'holder');
            }

            return order;
        },
        render: renderOperations,
        renderActions: () => <></>,
        url: 'balance',
        filterName: 'operations',
        info: {
            title: 'Операций пока нет',
            description: 'Они начнут здесь отображаться<br/>после первой оплаты',
        },
        getQuery: (): FilterQueryT[] => [],
    },
    clients: {
        title: {
            main: 'Всего',
            description(counter: number): string {
                return getEndText(counter, ['клиент', 'клиента', 'клиентов']);
            },
        },
        cols: {
            name: 'ФИО',
            status: 'Статус',
            login: 'Логин',
            orders: 'Заказы',
        },
        colsOrder: ['name', 'status', 'login', 'orders'],
        render: renderClients,
        renderActions: () => <></>,
        url: 'user',
        filterName: 'clients',
        info: {
            title: 'Клиентов пока нет',
            description: 'Они будут отображаться здесь<br/>в виде карточек',
        },
        getQuery: (): FilterQueryT[] => [],
    },
    workers: {
        title: {
            main: 'Всего',
            description(counter: number): string {
                return getEndText(counter, ['сотрудник', 'сотрудника', 'сотрудников']);
            },
        },
        cols: {
            name: 'ФИО',
            status: 'Статус',
            login: 'Логин',
        },
        colsOrder: ['name', 'status', 'login'],
        render: renderWorkers,
        renderActions: () => (
            <>
                <Media current="desktop" forAdmin={false}>
                    <AddButton
                        onClick={() => {
                            changePage({
                                pageName: 'worker',
                            });
                        }}
                    >
                        Добавить нового сотрудника
                    </AddButton>
                </Media>
            </>
        ),
        url: 'user',
        filterName: 'workers',
        info: {
            title: 'Сотрудников пока нет',
            description: 'Они будут отображаться здесь<br/>в виде карточек',
        },
        getQuery: (): FilterQueryT[] => [{ key: 'filter_admins', value: true }],
    },
    logs: {
        title: {
            main: 'Всего',
            description(counter: number): string {
                return getEndText(counter, ['лог', 'лога', 'логов']);
            },
        },
        cols: {
            name: 'Действие',
            holder: 'Пользователь',
            date: 'Дата',
        },
        colsOrder: ['name', 'holder', 'date'],
        render: renderLogs,
        renderActions: () => <></>,
        url: 'logs',
        filterName: 'logs',
        info: {
            title: 'Логов пока нет',
            description: 'Они будут отображаться здесь<br/>в виде карточек',
        },
        getQuery: (data: { modelName: string; modelId: string }): FilterQueryT[] => [
            { key: 'modelName', value: data.modelName },
            { key: 'modelId', value: data.modelId },
        ],
    },
} as const;

export default tables;
