import React from 'react';

import Calendar from '@components/calendar/Calendar.tsx';
import Media from '@components/media/Media.tsx';
import MobPopup from '@components/mobPopup/MobPopup.tsx';
import handlerPopup from '@functions/handlerPopup.ts';

import CalendarI from '../types.ts';

const renderMobile: CalendarI['renderMobile'] = function () {
    const { isInit } = this.state;
    const { calendarPopup, windowHeight } = this.props;
    const { startDate, callback } = calendarPopup;

    return (
        <Media current="mobile">
            <MobPopup
                windowHeight={windowHeight}
                hideCallback={() => {
                    handlerPopup('calendarPopup', { isShow: false });
                }}
                isInit={!!isInit}
            >
                <Calendar
                    callback={callback}
                    startDate={startDate}
                    init={() => {
                        this.setState({ isInit: true });
                    }}
                />
            </MobPopup>
        </Media>
    );
};

export default renderMobile;
