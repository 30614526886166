import React from 'react';

import Animate from '@components/animate/Animate.tsx';
import Link from '@components/link/Link.tsx';
import handlerPopup from '@functions/handlerPopup.ts';

import LegalNavI from '../types.ts';

const renderList: LegalNavI['renderList'] = function () {
    const { shortList, levels } = this.props;

    return (
        <Animate className="publicLegalNav__content" isShow={!!shortList}>
            {shortList?.map((item, index) => {
                const { title, url, _id } = item;
                const isCurrent = levels[2] ? url === levels[2] : index === 0;

                return (
                    <div className="publicLegalNav__item" key={_id}>
                        <Link
                            className="publicLegalNav__link"
                            pageName="public-legal-inner"
                            ids={{ 2: url }}
                            isCurrent={isCurrent}
                            callback={() => {
                                handlerPopup('legalNavPopup', { isShow: false });
                            }}
                        >
                            {title}
                        </Link>
                    </div>
                );
            })}
        </Animate>
    );
};

export default renderList;
