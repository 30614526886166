import React from 'react';

import Animate from '@components/animate/Animate.tsx';
import Loader from '@components/loader/Loader.tsx';

import imageLoad from './methods/imageLoad.ts';

import LazyImageI from './types.ts';

class LazyImage
    extends React.Component<LazyImageI['props'], LazyImageI['state']>
    implements LazyImageI
{
    parent: LazyImageI['parent'];

    constructor(props: LazyImageI['props']) {
        super(props);
        this.state = {
            isReady: false,
        };

        this.parent = React.createRef();
    }

    imageLoad = imageLoad;

    render() {
        const { isReady } = this.state;
        const { src, className = '', withLoader = true, cover, position } = this.props;

        return (
            <div ref={this.parent} className={`lazyImage ${isReady ? '_ready' : ''} ${className}`}>
                {withLoader && (
                    <Animate className="lazyImage__loader" isShow={!isReady}>
                        <Loader />
                    </Animate>
                )}

                <img
                    src={src}
                    alt=""
                    className="lazyImage__image"
                    onLoad={this.imageLoad.bind(this)}
                    style={{
                        objectFit: cover ? 'cover' : 'contain',
                        objectPosition: position || 'center',
                    }}
                />
            </div>
        );
    }
}

export default LazyImage;
