import React from 'react';
import { connect } from 'react-redux';

import EditBlock from '@components/editBlock/EditBlock.tsx';
import { ListenerT, StoreT } from '@global/types.ts';

import close from './methods/close.ts';
import getChange from './methods/getChange.ts';
import handlerKey from './methods/handlerKey.ts';
import send from './methods/send.ts';
import setSizeInit from './methods/setSizeInit.ts';
import startInit from './methods/startInit.ts';

import FilterI, { FilterPopupT } from './types.ts';

import renderBlock from './renders/renderBlock.tsx';
import renderButton from './renders/renderButton.tsx';
import renderContent from './renders/renderContent.tsx';
import renderDesktop from './renders/renderDesktop.tsx';
import renderMobile from './renders/renderMobile.tsx';
import checkFields from './static/checkFields.ts';
import info from './static/info.ts';

class FilterPopup extends EditBlock<FilterI['props'], FilterI['state']> implements FilterI {
    constructor(props: FilterI['props']) {
        super(props);
        this.state = {};

        this.handlerKey = this.handlerKey.bind(this);
    }

    info = info;
    checkFields = checkFields;

    getChange = getChange;
    handlerKey = handlerKey;
    close = close;
    send = send;
    startInit = startInit;
    setSizeInit = setSizeInit;

    renderContent = renderContent;
    renderDesktop = renderDesktop;
    renderMobile = renderMobile;
    renderBlock = renderBlock;
    renderButton = renderButton;

    componentDidMount() {
        this.startInit();
    }

    componentWillUnmount() {
        (document.removeEventListener as ListenerT<KeyboardEvent>)('keydown', this.handlerKey);
    }

    render() {
        return (
            <>
                {this.renderDesktop()}
                {this.renderMobile()}
            </>
        );
    }
}

function mapStateToProps(state: StoreT) {
    return {
        filterPopup: state.filterPopup as FilterPopupT,
        windowHeight: state.windowHeight,
    };
}

export default connect(mapStateToProps)(FilterPopup);
