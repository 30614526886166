import React from 'react';

import CalendarI from '../types.ts';

import Input from '../../input/Input.tsx';

const renderTimes: CalendarI['renderTimes'] = function () {
    const {
        currentDate,
        currentDateText,
        currentTime,
        updatedTimeKey,
        doubleCurrentDate,
        doubleCurrentDateText,
    } = this.state;
    const { inFilter, isDouble } = this.props;

    return (
        <div className="calendar__currents _ROW">
            <div className="calendar__current _ROW">
                <p className="calendar__currentSupport">{inFilter ? 'С' : 'Дата:'}</p>
                <div className="calendar__currentInput">
                    <Input
                        support="__/__/____"
                        name="calendar-date"
                        returnTemplate={true}
                        value={currentDateText}
                        reg="date"
                        className="_emptyBack"
                        onChange={this.setDateText.bind(this, 'currentDate')}
                        updatedKey={currentDate?.getTime()}
                        dateWithPast={isDouble}
                    />
                </div>
            </div>
            {!inFilter ? (
                <>
                    <div className="calendar__current _ROW _time">
                        <p className="calendar__currentSupport">Время:</p>
                        <div className="calendar__currentInput">
                            <Input
                                name="calendar-time"
                                returnTemplate={true}
                                value={currentTime}
                                reg="time"
                                className="_emptyBack"
                                onChange={this.setTime.bind(this)}
                                updatedKey={updatedTimeKey}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="calendar__current _ROW _time">
                        <p className="calendar__currentSupport">по</p>
                        <div className="calendar__currentInput">
                            <Input
                                support="__/__/____"
                                name="calendar-double-date"
                                returnTemplate={true}
                                value={doubleCurrentDateText}
                                reg="date"
                                className="_emptyBack"
                                onChange={this.setDateText.bind(this, 'doubleCurrentDate')}
                                updatedKey={doubleCurrentDate?.getTime()}
                                dateWithPast={true}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default renderTimes;
